import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";

import {
  withAuthentication,
  withAuthorization,
  AuthUserContext
} from "../Session";

import * as ROLES from "../../constants/roles";

import Footer from "../Footer";
import CheckInDialog from "../Admin/CheckInDialog";

import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Snackbar from "@material-ui/core/Snackbar";

import { withStyles } from "@material-ui/core/styles";

import Moment from "react-moment";
import { Helmet } from "react-helmet";

import Skeleton from "react-loading-skeleton";

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 2}px ${theme
      .spacing.unit * 6}px`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  adminContent: {
    backgroundColor: theme.palette.secondary[100]
  },
  content: {
    //backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
    maxWidth: 600,
    padding: theme.spacing.unit * 6
  },
  //additional for the Event Page
  eventPage: {
    flex: 1
  },
  heroImg: {
    overflow: "hidden",
    width: "100%"
  },
  fluidImgWide: {
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  fluidImgPortrait: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  heroMeta: {
    marginTop: theme.spacing.unit * 6
  },
  fab: {
    margin: theme.spacing.unit,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: 1
  },
  fabMoveUp: {
    transform: "translate3d(0, -70px, 0)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut
    })
  },
  fabMoveDown: {
    transform: "translate3d(0, 0, 0)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    })
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  highlight: {
    marginBottom: theme.spacing.unit * 3
  },
  /* Media queries adaptations for all the app */
  heroTitle: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: theme.typography.h3.fontweight,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      letterSpacing: theme.typography.h3.letterSpacing
    }
  },
  heroMetaSmall: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: theme.typography.subtitle1.fontweight,
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: theme.typography.subtitle1.lineHeight,
      letterSpacing: theme.typography.subtitle1.letterSpacing
    }
  }
});

class EventPage extends Component {
  constructor(props) {
    super(props);

    this.messageQueue = [];

    this.state = {
      loading: false,
      event: null,
      loadingRegistration: false,
      registration: null,
      isRegistered: false,
      messageOpen: false,
      messageInfo: {},
      ...props.location.state
    };
  }

  componentDidMount() {
    if (this.state.event) {
      return;
    }

    this.setState({ loading: true, loadingRegistration: true });

    this.unsubscribe = this.props.firebase
      .event(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          event: snapshot.data(),
          loading: false
        });
      });

    this.unsubscribeRegistration = this.props.firebase
      .userRegistrationsForEvent(
        this.props.authUser.uid,
        this.props.match.params.id
      )
      .onSnapshot(snapshot => {
        this.setState({
          isRegistered: !snapshot.empty,
          registration: snapshot.empty ? null : snapshot.docs[0].id,
          loadingRegistration: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribeRegistration && this.unsubscribeRegistration();
  }

  triggerSnackbar = event => {
    this.setState({ snackbarOpen: !this.state.snackbarOpen });
  };

  /* START snackbar message handlers */

  triggerMessage = message => () => {
    this.messageQueue.push({
      message,
      key: new Date().getTime()
    });

    if (this.state.messageOpen) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ messageOpen: false });
    } else {
      this.processMessageQueue();
    }
  };

  processMessageQueue = () => {
    if (this.messageQueue.length > 0) {
      this.setState({
        messageInfo: this.messageQueue.shift(),
        messageOpen: true
      });
    }
  };

  messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ messageOpen: false });
  };

  messageExited = () => {
    this.processMessageQueue();
  };

  /* END message handlers */

  /* START registration handlers */

  handleRegistration = event => {
    this.props.firebase
      .doRegistration(
        this.props.authUser,
        this.props.match.params.id,
        this.state.event
      )
      .then(this.triggerMessage("Te has registrado al evento"))
      .catch(err => this.triggerMessage(err));
  };

  handleDeleteRegistration = event => {
    this.props.firebase
      .deleteRegistration(this.state.registration)
      .then(this.triggerMessage("Has cancelado tu inscripción"))
      .catch(err => this.triggerMessage(err));
  };

  /* END registration handlers */

  render() {
    const {
      event,
      loading,
      //registration,
      isRegistered,
      //loadingRegistration,
      messageInfo
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.eventPage}>
        {loading && (
          /* START of SKELETON markup */
          <React.Fragment>
            {/* Hero unit */}
            <main>
              <div className={classes.heroUnit}>
                <div className={classes.heroImage}>
                  <Skeleton height={320} />
                </div>
                <div className={classes.heroContent}>
                  {/*<LinearProgress />*/}
                  <Typography
                    component="h1"
                    variant="h1"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    classes={{ h1: classes.heroTitle }}
                  >
                    <Skeleton />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    <Skeleton count={3} />
                  </Typography>
                </div>
              </div>
            </main>
          </React.Fragment>
          /* END of SKELETON markup */
        )}

        {event && (
          <React.Fragment>
            <Helmet>
              <title>{event.title}: un evento de Flames Barcelona</title>
            </Helmet>
            {/* Hero unit */}
            {!isRegistered && event.acceptsRegistration && (
              <Fab
                variant="extended"
                color="primary"
                size="large"
                aria-label="Register"
                className={classNames(
                  classes.fab,
                  this.state.messageOpen
                    ? classes.fabMoveUp
                    : classes.fabMoveDown
                )}
                onClick={this.handleRegistration}
              >
                <AddIcon className={classes.extendedIcon} />
                Registrarme
              </Fab>
            )}
            <main>
              <div className={classes.heroUnit}>
                <div className={classes.heroImage}>
                  <img
                    className={classes.fluidImgWide}
                    alt="Wide cover img"
                    src={event.imgW}
                  />
                  <img
                    className={classes.fluidImgPortrait}
                    alt="Portrait cover img"
                    src={event.imgP}
                  />
                </div>
                <div className={classes.heroContent}>
                  <Typography
                    component="h1"
                    variant="h1"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    classes={{ h1: classes.heroTitle }}
                  >
                    {event.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    {event.summary}
                  </Typography>

                  <div className={classes.heroMeta}>
                    <Grid container spacing={16} justify="center">
                      <Grid item xs>
                        <Typography
                          variant="button"
                          align="center"
                          gutterBottom
                        >
                          Ubicación
                        </Typography>
                        <Typography
                          variant="h5"
                          align="center"
                          gutterBottom
                          classes={{ h5: classes.heroMetaSmall }}
                        >
                          {event.location}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="button"
                          align="center"
                          gutterBottom
                        >
                          Fecha
                        </Typography>
                        <Typography
                          variant="h5"
                          align="center"
                          gutterBottom
                          classes={{ h5: classes.heroMetaSmall }}
                        >
                          <Moment unix format="DD/MM/YYYY">
                            {event.date.seconds}
                          </Moment>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="button"
                          align="center"
                          gutterBottom
                        >
                          Hora
                        </Typography>
                        <Typography
                          variant="h5"
                          align="center"
                          gutterBottom
                          classes={{ h5: classes.heroMetaSmall }}
                        >
                          <Moment unix format="HH:mm">
                            {event.date.seconds}
                          </Moment>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.heroButtons}>
                    <Grid container spacing={16} justify="center">
                      {/*{!isRegistered &&
                        event.acceptsRegistration (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleRegistration}
                            >
                              Registrarme
                            </Button>
                          </Grid>
                        )}*/}
                      {isRegistered && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={this.handleDeleteRegistration}
                          >
                            Cancelar mi inscripción
                          </Button>
                        </Grid>
                      )}
                      {!event.acceptsRegistration && (
                        <Grid item>
                          <Button variant="outlined" disabled>
                            Inscripciones cerradas
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </div>
              </div>
            </main>
            {/* End hero unit */}

            <AuthUserContext.Consumer>
              {authUser =>
                authUser.roles.includes(ROLES.STAFF) && (
                  <section className={classes.adminContent}>
                    <section className={classes.content}>
                      <Typography variant="h4" align="center" gutterBottom>
                        Registros y check-in
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        align="center"
                        component="p"
                      >
                        Ver los participantes y realizar el check-in
                      </Typography>
                      <Grid container spacing={16} justify="center">
                        <CheckInDialog
                          label="Acceder"
                          event={this.state.event}
                          eid={this.props.match.params.id}
                        />
                      </Grid>
                    </section>
                  </section>
                )
              }
            </AuthUserContext.Consumer>

            {/* Content */}
            <section className={classes.content}>
              <Typography
                className={classes.highlight}
                variant="h4"
                align="center"
                gutterBottom
              >
                {event.highlight}
              </Typography>

              {event.description.map((paragraph, i) => (
                <Typography
                  key={i}
                  variant="body1"
                  paragraph
                  align="center"
                  component="p"
                >
                  {paragraph}
                </Typography>
              ))}
            </section>
            {/* End Content */}
          </React.Fragment>
        )}
        {/* Otros elementos */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          key={messageInfo.key}
          open={this.state.messageOpen}
          autoHideDuration={6000}
          onClose={this.messageClose}
          onExited={this.messageExited}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{messageInfo.message}</span>}
        />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withStyles(styles),
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(EventPage);
