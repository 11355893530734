import React, { Component } from "react";
import { compose } from "recompose";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import GradientIcon from "@material-ui/icons/Gradient";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import MailIcon from "@material-ui/icons/Mail";
import SortIcon from "@material-ui/icons/Sort";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Checkbox from "@material-ui/core/Checkbox";
import Slide from "@material-ui/core/Slide";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import RemoveIcon from "@material-ui/icons/Remove";
import green from "@material-ui/core/colors/green";

import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";

import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import Moment from "react-moment";
import "moment/locale/es";

import { withFirebase } from "../Firebase";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: theme.spacing.unit * -0.5, //-12
    marginRight: theme.spacing.unit * 0.5 //20
  },
  menuButtonRight: {
    marginRight: theme.spacing.unit * -0.5, //-12
    marginLeft: theme.spacing.unit * 0.5,
    [theme.breakpoints.down("xs")]: {
      marginRight: -0.5, //-4,
      marginLeft: theme.spacing.unit * 0 //-12
    }
  },
  primaryText: {
    paddingLeft: theme.spacing.unit * 1
    /*[theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing.unit * 1.5
    }*/
  },
  secondaryAction: {
    [theme.breakpoints.up("sm")]: {
      right: theme.spacing.unit * 2
    }
  },
  avatar: {
    marginLeft: theme.spacing.unit * -4.5,
    marginRight: theme.spacing.unit * 4.5,
    [theme.breakpoints.up("sm")]: {
      //marginLeft: theme.spacing.unit * -4.5,
      marginRight: theme.spacing.unit * 5.5
    }
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500],
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 1
      //marginRight: theme.spacing.unit * 0
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class CheckInDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      registrations: [],
      checkInCount: 0,
      nameAsc: true,
      mailAsc: true,
      order: "name"
    };
  }

  componentDidMount() {
    if (this.state.registrations.empty) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .allRegistrationsForEvent(this.props.eid)
      .onSnapshot(snapshot => {
        let registrations = [];

        snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), id: doc.id })
        );
        /*snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), id: doc.id + 1 })
        );
        snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), id: doc.id + 2 })
        );*/

        let checkInCount = registrations.filter(reg => reg.checkedIn).length;

        let toggle = 1;

        if (this.state.order === "name") {
          if (!this.state.nameAsc) {
            toggle = -1;
          }

          registrations.sort((a, b) => {
            return a.username.toLowerCase() > b.username.toLowerCase()
              ? toggle
              : -toggle;
          });
        } else {
          if (!this.state.mailAsc) {
            toggle = -1;
          }

          registrations.sort((a, b) => {
            return a.email.toLowerCase() > b.email.toLowerCase()
              ? toggle
              : -toggle;
          });
        }

        this.setState({
          registrations,
          checkInCount,
          loading: false
        });

        if (this.state.order === "name") {
          //this.sortByName();
        } else {
          //this.sortByEmail();
        }
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  handleClickOpen = event => {
    this.setState({ open: true });
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  sortByName = event => {
    let registrations = this.state.registrations;

    let toggle = 1;
    if (this.state.nameAsc) {
      toggle = -1;
    }

    registrations.sort((a, b) => {
      return a.username.toLowerCase() > b.username.toLowerCase()
        ? toggle
        : -toggle;
    });

    this.setState({ registrations });
  };

  toggleByName = event => {
    let registrations = this.state.registrations;

    let toggle = 1;
    if (!this.state.nameAsc) {
      toggle = -1;
    }

    registrations.sort((a, b) => {
      return a.username.toLowerCase() > b.username.toLowerCase()
        ? toggle
        : -toggle;
    });

    this.setState({
      registrations,
      order: "name"
    });
  };

  sortByEmail = event => {
    let registrations = this.state.registrations;

    let toggle = 1;
    if (!this.state.mailAsc) {
      toggle = -1;
    }

    registrations.sort((a, b) => {
      return a.email.toLowerCase() > b.email.toLowerCase() ? toggle : -toggle;
    });

    this.setState({ registrations });
  };

  toggleByEmail = event => {
    let registrations = this.state.registrations;

    let toggle = 1;
    if (!this.state.mailAsc) {
      toggle = -1;
    }

    registrations.sort((a, b) => {
      return a.email.toLowerCase() > b.email.toLowerCase() ? toggle : -toggle;
    });

    this.setState({
      registrations,
      order: "email"
    });
  };

  toggleCheckIn = rid => event => {
    console.log("toggle " + rid);
    let registrations = this.state.registrations;
    let reg = registrations.find(reg => reg.id === rid);
    let index = registrations.findIndex(reg => reg.id === rid);

    if (reg.checkedIn == true) {
      this.props.firebase.undoCheckIn(rid);
    } else {
      this.props.firebase.doCheckIn(rid);
    }

    //reg.checkedIn = !reg.checkedIn; // Cambiar por funcionalidad de backend con firestore
    //registrations.splice(index, reg);
    //this.setState({ registrations });
  };

  render() {
    const { open, loading, registrations, checkInCount } = this.state;
    const { classes, event } = this.props;

    return (
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleClickOpen}
        >
          {this.props.label}
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar} color="secondary">
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.flex}
              >
                {checkInCount}
                {"/"}
                {registrations.length}: Check-in para {event.title}
                {/*{this.props.event.title} si no hay la definición const en render*/}
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.toggleByName}
                aria-label="Scan"
                className={classes.menuButtonRight}
              >
                <TextFormatIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={this.toggleByEmail}
                aria-label="Scan"
                className={classes.menuButtonRight}
              >
                <MailOutlineIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List className={classes.root}>
            {registrations &&
              registrations.map(registration => (
                <React.Fragment key={registration.id}>
                  <ListItem
                    alignItems="flex-start"
                    key={registration.id}
                    button
                    onClick={this.toggleCheckIn(registration.id)}
                  >
                    {(registration.checkedIn && (
                      <Zoom in={registration.checkedIn}>
                        <ListItemAvatar>
                          <Avatar
                            className={classNames(
                              classes.greenAvatar,
                              classes.avatar
                            )}
                          >
                            <SentimentVerySatisfiedIcon />
                          </Avatar>
                        </ListItemAvatar>
                      </Zoom>
                    )) || (
                      <Slide direction="right" in={!registration.checkedIn}>
                        <ListItemAvatar className={classes.avatar}>
                          <Avatar>
                            <RemoveIcon />
                          </Avatar>
                        </ListItemAvatar>
                      </Slide>
                    )}
                    <ListItemText
                      primary={registration.username}
                      className={classes.primaryText}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {registration.email}
                          </Typography>
                          {" — Registrado el "}
                          <Moment unix format="ll" locale="es">
                            {registration.registered.seconds}
                          </Moment>
                          {" a las "}
                          <Moment unix format="HH:mm" locale="es">
                            {registration.registered.seconds}
                          </Moment>
                        </React.Fragment>
                      }
                    />
                    {/*
                    <ListItemSecondaryAction
                      className={classes.secondaryAction}
                    >
                      <Checkbox
                        checked={registration.checkedIn}
                        tabIndex={-1}
                        disableRipple
                        onClick={this.toggleCheckIn(registration.id)}
                      />
                    </ListItemSecondaryAction>*/}
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
          </List>
        </Dialog>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withFirebase
)(CheckInDialog);
