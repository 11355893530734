export const ABOUT = "/about";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/";
export const EVENT = "/event";
export const EVENT_DETAILS = "/event/:id";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";
export const PASSWORD_FORGET = "/forgot";
export const ADD_EVENT = "/add";
