import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import PropTypes from "prop-types";
import classNames from "classnames";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Footer from "../Footer";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FlamesBarcelonaBadge from "../SVG";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../Theme/withTheme";

/*
const SignInPage = () => (
  <div>
    <h1>SignIn</h1>
    <SignInForm />
    <SignInGoogle />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);*/

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    //marginBottom: theme.spacing.unit * 5,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  content: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    textAlign: "center",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,

    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  actions: {
    marginBottom: theme.spacing.unit * 3
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  googleButton: {
    //color: theme.palette.getContrastText(purple[500]),
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white[700]
    }
  },
  loader: {
    textAlign: "center",
    marginTop: theme.spacing.unit * 2
  }
});

const SignInPage = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {/*<Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>*/}
          <FlamesBarcelonaBadge width={100} height={100} />
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <SignInForm />
        </Paper>
      </main>

      <section className={classes.content}>
        <div className={classes.actions}>
          <SignInGoogle />
        </div>
        {/*<div className={classes.actions}>
          <SignInFacebook />
        </div>*/}
        <div className={classes.actions}>
          <PasswordForgetLink />
        </div>
        <div className={classes.actions}>
          <SignUpLink />
        </div>
      </section>
    </React.Fragment>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
  loading: false,
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  Ya existe una cuenta para tu dirección de correo electrónico, 
  inicia sesión con ella para vincular este proveedor de identidad.
   `;
//An account with an E-Mail address to
//this social account already exists. Try to login from
//this account instead and associate your social accounts on
//your personal account page.

const ERROR_INVALID_EMAIL = "auth/invalid-email";
const MSG_INVALID_EMAIL = "La dirección de correo no tiene un formato válido.";
//The email address is badly formatted.

const ERROR_USER_NOT_FOUND = "auth/user-not-found";
const MSG_USER_NOT_FOUND = "No existe un usuario para este identificador.";
//There is no user record corresponding to this identifier. The user may have been deleted.

const ERROR_WRONG_PASSWORD = "auth/wrong-password";
const MSG_WRONG_PASSWORD =
  "La contraseña es incorrecta o el usuario no ha establecido una.";
//The password is invalid or the user does not have a password.

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ loading: true });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        let targetRoute = sessionStorage.getItem("targetRoute");
        sessionStorage.removeItem("targetRoute");
        this.props.history.push(
          targetRoute === null ? ROUTES.HOME : targetRoute
        );
      })
      .catch(error => {
        if (error.code === ERROR_INVALID_EMAIL) {
          error.message = MSG_INVALID_EMAIL;
        } else if (error.code === ERROR_USER_NOT_FOUND) {
          error.message = MSG_USER_NOT_FOUND;
        } else if (error.code === ERROR_WRONG_PASSWORD) {
          error.message = MSG_WRONG_PASSWORD;
        }
        this.setState({ error, loading: false });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, loading } = this.state;
    const { classes } = this.props;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Correo electrónico</InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            onChange={this.onChange}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Contraseña</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
          />
          {error && (
            <FormHelperText id="component-error-text">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>

        {!loading && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Iniciar sesión
          </Button>
        )}
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </form>
    );
  }
}

function GoogleIcon(props) {
  return (
    <svg
      className={props.className}
      width="24px"
      height="24px"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          id="a"
          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
        />
      </defs>
      <clipPath id="b">
        <use xlinkHref="#a" overflow="visible" />
      </clipPath>
      <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
      <path
        clipPath="url(#b)"
        fill="#EA4335"
        d="M0 11l17 13 7-6.1L48 14V0H0z"
      />
      <path
        clipPath="url(#b)"
        fill="#34A853"
        d="M0 37l30-23 7.9 1L48 0v48H0z"
      />
      <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
    </svg>
  );
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, loading: false };
  }

  onSubmit = event => {
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            photoURL: socialAuthUser.user.photoURL,
            phoneNumber: socialAuthUser.user.phoneNumber
            //roles: []
          },
          { merge: true }
        );
      })
      .then(() => {
        this.setState({ error: null, loading: false });
        let targetRoute = sessionStorage.getItem("targetRoute");
        sessionStorage.removeItem("targetRoute");
        this.props.history.push(
          targetRoute === null ? ROUTES.HOME : targetRoute
        );
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error, loading: false });
      });

    event.preventDefault();
  };
  render() {
    const { error, loading } = this.state;
    const { classes } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        {!loading && (
          <Button
            type="submit"
            variant="contained"
            className={classNames(classes.submit, classes.googleButton)}
          >
            <GoogleIcon className={classes.leftIcon} />
            Iniciar sesión con Google
          </Button>
        )}
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        <FormHelperText id="component-error-text">
          {error && <p>{error.message}</p>}
        </FormHelperText>
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, loading: false, open: false };
    this.provider = null;
    this.pendingCred = null;
  }

  onSubmit = event => {
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            photoURL:
              socialAuthUser.additionalUserInfo.profile.picture.data.url,
            phoneNumber: socialAuthUser.user.phoneNumber
            //roles: []
          },
          { merge: true }
        );
      })
      .then(() => {
        this.setState({ error: null, loading: false });
        let targetRoute = sessionStorage.getItem("targetRoute");
        sessionStorage.removeItem("targetRoute");
        this.props.history.push(
          targetRoute === null ? ROUTES.HOME : targetRoute
        );
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;

          this.pendingCred = error.credential;

          this.props.firebase.auth
            .fetchSignInMethodsForEmail(error.email)
            .then(methods => {
              //for email-password login
              if (methods[0] === "password") {
                //ask for password
                this.props.firebase
                  .doSignInWithEmailAndPassword(error.email, "123456")
                  .then(userCredential => {
                    return userCredential.user.linkWithCredential(
                      this.pendingCred
                    );
                  })
                  .then(function() {
                    //go to app
                    this.setState({ error: null, loading: false });
                    let targetRoute = sessionStorage.getItem("targetRoute");
                    sessionStorage.removeItem("targetRoute");
                    this.props.history.push(
                      targetRoute === null ? ROUTES.HOME : targetRoute
                    );
                  });
                return;
              }

              //for other social logins (for now... just Google)
              this.provider = this.props.firebase.getProviderForProviderId(
                methods[0]
              );
              this.handleClickOpen();
            });
        }

        this.setState({ error, loading: false });
      });

    event.preventDefault();
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleLink = () => {
    this.handleClose();
    this.props.firebase.auth.signInWithPopup(this.provider).then(result => {
      result.user
        .linkAndRetrieveDataWithCredential(this.pendingCred)
        .then(usercred => {
          this.setState({ error: null, loading: false });
          let targetRoute = sessionStorage.getItem("targetRoute");
          sessionStorage.removeItem("targetRoute");
          this.props.history.push(
            targetRoute === null ? ROUTES.HOME : targetRoute
          );
        });
    });
  };

  render() {
    const { error, loading, open } = this.state;
    const { classes } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        {!loading && (
          <Button
            type="submit"
            variant="contained"
            className={classNames(classes.submit, classes.googleButton)}
          >
            <GoogleIcon className={classes.leftIcon} />
            Iniciar sesión con Facebook
          </Button>
        )}
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        <FormHelperText id="component-error-text">
          {error && <p>{error.message}</p>}
        </FormHelperText>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Ya tienes una cuenta de usuario"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ya has registrado previamente tu correo electrónico con una cuenta
              de Google. Inicia sesión con Google para poder vincular también tu
              cuenta de Facebook.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleLink} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}

const SignInForm = compose(
  withTheme,
  withStyles(styles),
  withRouter,
  withFirebase
)(SignInFormBase);

const SignInGoogle = compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(SignInGoogleBase);

const SignInFacebook = compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(SignInFacebookBase);

SignInPage.propTypes = {
  classes: PropTypes.object.isRequired
};

//export default SignInPage;

export default compose(
  withTheme,
  withStyles(styles)
)(SignInPage);

export { SignInForm, SignInGoogle };
