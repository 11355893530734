import React, { Component } from "react";
import { compose } from "recompose";

import * as ROUTES from "../../constants/routes";
import { withRouter } from "react-router-dom";

import { withFirebase } from "../Firebase";

import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { withStyles } from "@material-ui/core/styles";

/*
const SignOutButton = ({ firebase }) => (
  <button type="button" onClick={firebase.doSignOut}>
    Sign Out
  </button>
);*/

const styles = theme => ({
  menuButton: {
    marginLeft: theme.spacing.unit * -0.5, //-12
    marginRight: theme.spacing.unit * 0.5 //20
  },
  menuButtonRight: {
    marginRight: theme.spacing.unit * -0.5, //-12
    marginLeft: theme.spacing.unit * 0.5 //20
  }
});

class SignOutButtonBase extends Component {
  handleClick = event => {
    this.props.firebase.doSignOut().then(() => {
      this.props.history.push(ROUTES.HOME);
    });
  };

  render() {
    return (
      <button type="button" onClick={this.handleClick}>
        Sign Out
      </button>
    );
  }
}

class SignOutIconButtonBase extends Component {
  handleClick = event => {
    this.props.firebase.doSignOut().then(() => {
      this.props.history.push(ROUTES.HOME);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <IconButton
        className={classes.menuButtonRight}
        color="inherit"
        aria-label="Sign Out"
        onClick={this.handleClick}
      >
        <ExitToAppIcon />
      </IconButton>
    );
  }
}

const SignOutButton = compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(SignOutButtonBase);

const SignOutIconButton = compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(SignOutIconButtonBase);

export { SignOutIconButton };
export default SignOutButton;
