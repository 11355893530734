import React from "react";

function FlamesBarcelonaBadge(props) {
  return (
    <svg width={props.width} height={props.height}>
      <g>
        <g>
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M49.76,45.75c0.04-0.04,0.06-0.08,0.06-0.13c0-0.04-0.02-0.07-0.07-0.1c-0.04-0.03-0.14-0.05-0.28-0.05
			c-0.14,0-0.25,0.04-0.33,0.13c-0.08,0.09-0.13,0.18-0.15,0.29c-0.05,0.13-0.07,0.26-0.06,0.4v1.38c0,0.14,0,0.29,0.01,0.45
			c0.01,0.16,0.03,0.31,0.06,0.45c0.03,0.14,0.08,0.26,0.15,0.35c0.07,0.1,0.17,0.15,0.31,0.15c0.2,0,0.35-0.07,0.43-0.21
			c0.08-0.14,0.14-0.3,0.16-0.48c0.03-0.2,0.01-0.44-0.04-0.71L49.76,45.75z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M63.12,86.08c0.04-0.04,0.06-0.08,0.06-0.13c0-0.04-0.02-0.07-0.07-0.11c-0.04-0.03-0.14-0.05-0.28-0.05
			s-0.25,0.04-0.33,0.13c-0.08,0.09-0.13,0.18-0.15,0.29c-0.05,0.13-0.07,0.26-0.06,0.4v1.38c0,0.14,0,0.29,0.01,0.45
			c0,0.16,0.03,0.31,0.06,0.45c0.03,0.14,0.08,0.26,0.15,0.35s0.18,0.14,0.32,0.14c0.2,0,0.35-0.07,0.43-0.21
			c0.08-0.14,0.13-0.3,0.16-0.48c0.03-0.2,0.01-0.44-0.04-0.71L63.12,86.08z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M63.06,93.47c-0.14-0.1-0.28-0.09-0.44,0.04c-0.16,0.14-0.33,0.36-0.5,0.67
			c-0.17,0.31-0.34,0.66-0.52,1.03c-0.17,0.38-0.34,0.75-0.5,1.14c-0.07,0.16-0.13,0.3-0.2,0.44c0.95-0.19,1.9-0.41,2.84-0.66
			c-0.1-0.61-0.22-1.22-0.36-1.82C63.3,93.85,63.19,93.57,63.06,93.47z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M82.07,63.53c-0.71-1.19-1.3-0.81-1.14,0.35c0.18,1.33-0.28,5.31-2.37,5.59
			c-5.91,0.79-0.22-8.64,0.06-11.39c0.25-2.41,0.11-5.48-1.33-7.41c-0.21-0.29-1.43-1.75-1.87-1.51c-0.82,0.46-0.07,1.03,0.01,1.66
			c0.52,3.97-6.22,5.52-6.37,0.81c-0.09-2.9,1.92-4.23,2.73-6.74c0.67-2.09,0.61-4.46,0.07-6.57c-0.74-2.9-1.88-3.79-4.22-5.12
			c-0.67-0.38-1.89-1.01-2.7-0.72c-0.9,0.32,0.68,1.41,0.86,2.6c0.07,0.46,0.08,0.87,0.06,1.22c0.08,0.27,0.14,0.55,0.18,0.84
			c0.04,0.27,0.06,0.53,0.06,0.78c0.62-0.24,0.42-0.75,0.59-0.59c0.18,0.18,0.17,0.78,0.08,1.12c-0.12,0.45-0.34,0.7-0.71,0.95
			c-0.14,0.09-0.27,0.19-0.35,0.29c-0.03,0.08-0.07,0.16-0.11,0.24c-0.01,0.1,0.01,0.2,0.08,0.33c0.02,0.03,0.03,0.05,0.05,0.07
			c-0.17,0.13-0.34,0.2-0.48,0.2c-0.03,0.04-0.05,0.08-0.08,0.12c-0.31,0.43-0.63,0.77-0.95,1.02c-0.32,0.26-0.53,0.41-0.63,0.46
			c-0.11,0.05-0.24,0.13-0.38,0.22c-0.14,0.1-0.28,0.23-0.42,0.4c-0.14,0.17-0.28,0.39-0.42,0.65c-0.14,0.26-0.27,0.58-0.38,0.96
			c-0.05,0.34,0.04,0.7,0.27,1.06c0.24,0.36,0.53,0.71,0.87,1.03c0.34,0.33,0.69,0.62,1.03,0.9c0.34,0.27,0.6,0.49,0.76,0.66
			c0.15,0.15,0.32,0.35,0.51,0.59s0.35,0.51,0.5,0.8c0.15,0.29,0.26,0.6,0.33,0.92c0.08,0.32,0.08,0.63,0.01,0.93
			c-0.07,0.3-0.23,0.58-0.47,0.85c-0.24,0.27-0.61,0.5-1.11,0.69c-0.71,0.22-1.31,0.27-1.79,0.16c-0.48-0.11-0.87-0.32-1.18-0.63
			c-0.31-0.31-0.55-0.7-0.72-1.16c-0.17-0.46-0.31-0.93-0.4-1.41c-0.1-0.48-0.17-0.94-0.23-1.38c-0.06-0.43-0.12-0.78-0.2-1.05
			c-0.11-0.34-0.22-0.62-0.33-0.82c-0.11-0.2-0.21-0.36-0.3-0.47s-0.19-0.18-0.27-0.22c-0.08-0.04-0.16-0.06-0.22-0.06
			c-0.12,0-0.19,0.05-0.23,0.15c-0.04,0.1-0.06,0.22-0.06,0.36c0,0.14,0.01,0.29,0.03,0.44c0.02,0.15,0.03,0.28,0.03,0.38
			c0,0.34,0.06,0.88,0.17,1.6c0.11,0.72,0.29,1.55,0.53,2.46c0.1,0.41,0.16,0.84,0.16,1.29c0.01,0.45-0.08,0.86-0.25,1.23
			c-0.17,0.37-0.45,0.67-0.83,0.89c-0.06,0.04-0.14,0.06-0.21,0.09c0.02-0.56,0.27-1.18-0.06-1.64c-0.38-0.53-1.17-0.65-1.34-1.38
			c-0.03-0.15,0-0.36-0.16-0.32c-0.11,0.03-0.19,0.29-0.22,0.41c-0.07,0.31-0.07,0.63,0.07,0.91c0.14,0.28,0.37,0.56,0.37,0.91
			c0,0.32-0.33,0.62-0.56,0.76c-0.29,0.17-0.47,0.09-0.54-0.27c-0.03-0.15-0.03-0.32,0.02-0.47c0.02-0.06,0.07-0.13,0.09-0.21
			c0.02-0.07-0.07-0.12-0.1-0.06c-0.19,0.29-0.36,0.54-0.46,0.88c-0.3-0.28-0.49-0.6-0.56-0.95c-0.07-0.36-0.05-0.72,0.06-1.09
			c0.11-0.37,0.25-0.7,0.43-0.99c0.15-0.18,0.25-0.53,0.28-1.07c0.03-0.53,0.03-1.18-0.02-1.93c-0.05-0.75-0.12-1.58-0.21-2.47
			c-0.09-0.9-0.2-1.81-0.32-2.74c-0.12-0.93-0.24-1.84-0.35-2.72c-0.12-0.89-0.21-1.68-0.29-2.4c-0.09-0.57-0.13-1.08-0.12-1.53
			c0.01-0.45,0.06-0.85,0.14-1.2c0.08-0.36,0.19-0.66,0.32-0.92c0.13-0.25,0.27-0.48,0.42-0.67c0.34-0.43,0.77-0.74,1.28-0.92
			c0.37-0.17,0.77-0.31,1.22-0.42c0.38-0.1,0.83-0.18,1.36-0.24c0.52-0.06,1.09-0.05,1.72,0.01c0.38,0.06,0.74,0.14,1.09,0.25
			c1.06-2.23,1.36-4.06,0.68-6.57c-0.52-1.91-2.2-4.92-3.61-5.26c-1.4-0.33,1.53,1.8-1.61,5.14c-1.28,1.35-2.46,1.15-3.1-0.43
			c-1.73-4.29,1.58-10.54-1.54-14.37c-2.83-3.49-8.28-3.86-9.79-8.81c-0.31-1.03-0.15-2.49-1.22-2.14
			c-0.77,0.26-1.21,2.13-1.34,2.91c-0.36,2.17-0.22,4.38,0.85,6.24c1.06,1.84,2.77,3.7,2.89,6.06c0.12,2.21-2.01,4.45-3.53,5.5
			c-1.91,1.32-3.21,0.88-3.85-1.56c-0.27-1.05-0.35-2.21-0.08-3.27c0.11-0.4,0.46-0.96,0.56-1.46c0.11-0.5-0.51-0.78-0.75-0.34
			c-1.35,2.43-2.57,4.41-3.09,7.39c-0.51,2.96,0.38,5.96-0.12,8.86c-0.08,0.47-0.27,0.98-0.53,1.49c0.41,0.03,0.87,0.09,1.4,0.17
			c0.82,0.13,1.52,0.35,2.11,0.67c0.59,0.32,1.07,0.7,1.44,1.15c0.37,0.45,0.62,0.93,0.76,1.45c0.14,0.52,0.17,1.04,0.1,1.56
			c-0.07,0.52-0.25,1.03-0.53,1.52c-0.29,0.49-0.68,0.92-1.17,1.29c-0.41,0.37-0.68,0.77-0.82,1.21c-0.14,0.44-0.17,0.89-0.1,1.37
			c0.08,0.47,0.25,0.94,0.52,1.42c0.27,0.48,0.6,0.94,0.98,1.38c0.39,0.44,0.83,0.85,1.32,1.24c0.49,0.39,1,0.72,1.54,1
			c0.74,0.43,1.31,0.89,1.72,1.37c0.41,0.48,0.66,0.97,0.77,1.46c0.11,0.49,0.08,0.98-0.09,1.46c-0.17,0.48-0.47,0.94-0.91,1.37
			c-0.28,0.33-0.64,0.61-1.07,0.83c-0.43,0.22-0.89,0.4-1.38,0.54c-0.49,0.14-0.99,0.25-1.51,0.32c-0.52,0.07-1,0.11-1.45,0.13
			c-0.45,0.02-0.85,0.02-1.2,0c-0.35-0.02-0.61-0.05-0.77-0.09c-0.13-0.04-0.31-0.06-0.55-0.08c-0.24-0.01-0.5-0.04-0.78-0.08
			c-0.28-0.04-0.56-0.1-0.85-0.19c-0.29-0.09-0.54-0.23-0.75-0.42c-0.22-0.19-0.38-0.44-0.48-0.76c-0.1-0.31-0.11-0.71-0.04-1.21
			c0.01-0.1,0.01-0.21,0.02-0.34c-0.09-0.03-0.18-0.06-0.27-0.12c-0.07-0.07-0.14-0.14-0.22-0.22c-0.71-0.68-1.07-1.3-1.15-2.33
			c-0.06-0.76,0.15-2.06,0.61-2.37c0.42-0.28-0.2,0.68,0.92,1.44c0-0.05-0.01-0.1-0.01-0.16c-0.05-0.92-0.11-1.91-0.19-2.97
			c-0.08-1.06-0.16-2.11-0.25-3.15c-0.18-2.22-0.39-4.62-0.63-7.18c-0.27,0.06-0.54,0.07-0.82,0.02c-2.42-0.45-0.68-4.3-1.71-5.38
			c-0.17-0.18-0.44-0.1-0.54,0.13c-0.98,2.47-2.37,5.02-2.2,7.77c0.15,2.59,0.85,5.05,1.19,7.58c0.21,1.54-1.13,5.15-2.86,3.08
			c-0.91-1.09-0.11-4.04-0.95-4.98c-0.14-0.15-0.38-0.09-0.44,0.12c-0.65,2.14-1.44,4.43-1.49,6.7c-0.06,2.47,0.94,4.27,1.99,6.33
			c0.9,1.76,4.19,10.97-0.66,8.87c-1.88-0.81-2.28-2.57-2.31-4.62c-0.02-1.03,0.48-4.33-0.98-2.62c-0.96,1.13-1.93,4.4-2.17,5.92
			c-0.36,2.31,0.09,4.9,0.41,7.18c0.03,0.21,0.02,0.15,0,0.01c0.38,2.73,2.26,5.82,3.61,8.05c0.81,1.35,2.85,5.24,6.63,9.14
			c0-0.04-0.01-0.07-0.02-0.11c-0.06-0.65-0.05-1.29,0.01-1.92c0.06-0.63,0.16-1.23,0.29-1.79c0.11-0.51,0.26-1.01,0.42-1.51
			c-0.1-0.11-0.19-0.23-0.28-0.36c0,0,0-0.01,0-0.02c-0.03-0.44-0.13-0.88-0.13-1.33c0-0.48,0.26-0.91,0.46-1.32
			c0.02-0.04,0.07-0.05,0.09-0.02c0.17,0.2-0.17,0.85,0.25,0.96c0.18,0.05,0.38-0.06,0.53-0.23c0.01-0.03,0.02-0.06,0.04-0.09
			c0.03-0.1,0.11-0.26,0.23-0.47c0.08-0.47-0.01-0.96,0.1-1.44c0.12-0.51,0.35-0.84,0.61-1.24c0.05-0.07,0.15-0.02,0.13,0.07
			c-0.02,0.08-0.09,0.18-0.11,0.25c-0.06,0.18-0.06,0.38-0.02,0.57c0.07,0.35,0.23,0.47,0.46,0.41c0.04-0.03,0.07-0.06,0.11-0.1
			c0.47-0.38,1.06-0.69,1.78-0.95c0.71-0.26,1.56-0.33,2.56-0.23c1.03,0.12,1.88,0.39,2.56,0.83c0.67,0.44,1.23,1,1.66,1.68
			c0.43,0.68,0.77,1.45,1,2.3c0.24,0.85,0.44,1.74,0.6,2.67c0.15,0.94,0.15,1.88-0.01,2.82c-0.16,0.93-0.42,1.81-0.78,2.62
			c-0.36,0.81-0.8,1.52-1.32,2.14c-0.51,0.62-1.06,1.08-1.63,1.38c-0.34,0.2-0.72,0.41-1.13,0.61c-0.05,0.02-0.09,0.04-0.14,0.06
			c1.29,0.67,2.67,1.27,4.15,1.78c3.97,1.36,9.52,2.21,15.44,1.93c-0.08-0.38-0.08-0.77-0.01-1.18c0.09-0.53,0.34-1.03,0.74-1.52
			c0.17-0.24,0.37-0.66,0.61-1.25c0.24-0.59,0.5-1.29,0.78-2.09c0.28-0.8,0.57-1.68,0.86-2.62c0.29-0.95,0.59-1.9,0.88-2.85
			c0.66-2.24,1.36-4.7,2.1-7.39c0.02-0.08,0.06-0.16,0.09-0.24c-0.07-0.59-0.25-1.22,0.09-1.69c0.46-0.64,1.4-0.78,1.61-1.65
			c0.04-0.19,0-0.44,0.19-0.39c0.14,0.03,0.23,0.35,0.26,0.49c0.08,0.37,0.08,0.76-0.09,1.09c-0.07,0.15-0.17,0.3-0.25,0.45
			c0.17-0.02,0.35-0.02,0.56,0.01c0.41,0.08,0.75,0.25,1.04,0.54c0.29,0.28,0.53,0.62,0.72,1.01c0.19,0.39,0.34,0.82,0.46,1.28
			c0.11,0.46,0.21,0.89,0.29,1.3c0.04,0.23,0.12,0.66,0.24,1.31c0.12,0.64,0.26,1.4,0.42,2.28c0.16,0.88,0.34,1.83,0.53,2.84
			c0.2,1.02,0.4,2.02,0.61,3c0.21,0.98,0.42,1.9,0.62,2.75c0.15,0.61,0.29,1.14,0.43,1.59c5.57-2.3,10.6-6.1,13.99-11.95
			C84.39,78.85,85.53,69.36,82.07,63.53z M56.19,77.21c-0.01,0.12-0.01,0.24-0.02,0.36c-0.04,0.43-0.09,0.84-0.16,1.23
			c-0.07,0.39-0.14,0.71-0.2,0.95c-0.06,0.26-0.12,0.61-0.17,1.07c-0.05,0.46-0.1,0.97-0.14,1.53c-0.04,0.56-0.08,1.14-0.11,1.74
			c-0.02,0.6-0.05,1.16-0.06,1.7c-0.02,0.54-0.03,1.01-0.04,1.42c-0.01,0.41-0.01,0.71-0.01,0.89c0,0.27,0.01,0.6,0.05,0.99
			c0.03,0.39,0,0.78-0.09,1.16c-0.09,0.38-0.28,0.71-0.56,1c-0.29,0.29-0.74,0.46-1.37,0.51c-0.4,0.04-0.76-0.01-1.1-0.15
			c-0.34-0.14-0.65-0.33-0.93-0.58c-0.29-0.25-0.55-0.53-0.79-0.85c-0.24-0.32-0.47-0.64-0.68-0.97c-0.21-0.33-0.4-0.65-0.58-0.95
			c-0.18-0.31-0.34-0.57-0.5-0.78c-0.18-0.28-0.39-0.57-0.64-0.86c-0.25-0.29-0.51-0.56-0.78-0.8c-0.28-0.24-0.55-0.44-0.84-0.59
			c-0.29-0.15-0.56-0.23-0.83-0.23c-0.11,0.04-0.21,0.19-0.28,0.45c-0.07,0.26-0.12,0.58-0.16,0.96c-0.04,0.38-0.06,0.79-0.08,1.24
			c-0.01,0.45-0.02,0.88-0.03,1.3c0,0.42,0,0.8,0,1.14c0.01,0.34,0.01,0.6,0.01,0.76c0.03,0.23,0.05,0.5,0.08,0.8
			c0.02,0.31,0.03,0.62,0.03,0.93c-0.01,0.32-0.04,0.63-0.11,0.94c-0.03,0.16-0.08,0.3-0.13,0.43c-0.11-0.38-0.29-0.65-0.5-0.97
			c-0.04-0.07-0.13-0.02-0.11,0.06c0.02,0.07,0.08,0.16,0.1,0.22c0.05,0.16,0.05,0.34,0.02,0.51c-0.08,0.39-0.27,0.47-0.58,0.29
			c-0.25-0.15-0.6-0.47-0.6-0.81c0-0.37,0.25-0.67,0.39-0.97c0.15-0.3,0.15-0.65,0.08-0.98c-0.03-0.12-0.11-0.41-0.23-0.44
			c-0.17-0.04-0.13,0.18-0.17,0.35c-0.19,0.78-1.03,0.9-1.44,1.48c-0.17,0.24-0.2,0.52-0.17,0.82c-0.02-0.04-0.04-0.08-0.06-0.12
			c-0.15-0.36-0.27-0.76-0.34-1.2c-0.07-0.44-0.11-0.87-0.12-1.3c-0.01-0.43-0.01-0.82,0.02-1.19c0.03-0.37,0.05-0.68,0.08-0.94
			c0-0.15,0-0.39-0.01-0.72c-0.01-0.33-0.02-0.71-0.03-1.15c-0.01-0.43-0.02-0.9-0.03-1.39c-0.01-0.5-0.02-0.99-0.03-1.49
			c-0.03-1.17-0.05-2.44-0.08-3.8c0-0.47,0.05-0.95,0.13-1.44c0.09-0.49,0.22-0.93,0.4-1.31c0.18-0.38,0.4-0.68,0.67-0.9
			c0.27-0.22,0.58-0.29,0.93-0.23c0.28,0.03,0.59,0.17,0.93,0.45c0.34,0.27,0.68,0.61,1.02,1.01c0.34,0.4,0.68,0.84,1.02,1.33
			c0.34,0.48,0.65,0.95,0.93,1.4c0.29,0.45,0.54,0.87,0.75,1.24c0.21,0.38,0.36,0.65,0.45,0.83c0.13,0.23,0.28,0.49,0.46,0.79
			c0.18,0.3,0.39,0.57,0.63,0.82c0.24,0.25,0.51,0.44,0.8,0.56c0.29,0.13,0.62,0.13,0.99,0.02c0.32-0.1,0.5-0.35,0.54-0.75
			c0.04-0.4,0.03-0.87-0.06-1.42c-0.08-0.54-0.18-1.12-0.3-1.74c-0.12-0.61-0.18-1.18-0.18-1.72c-0.02-0.28-0.02-0.55-0.02-0.82
			c-0.41-0.08-0.82-0.15-1.2-0.21c-0.6-0.09-1.2-0.17-1.81-0.25c-0.75-0.22-1.34-0.52-1.76-0.92c-0.42-0.4-0.73-0.84-0.94-1.33
			c-0.2-0.48-0.32-0.99-0.34-1.51c-0.03-0.52-0.01-1.01,0.05-1.45c0.06-0.45,0.13-0.83,0.23-1.17c0.1-0.33,0.16-0.55,0.2-0.65
			c0-0.1,0.03-0.36,0.09-0.78c0.07-0.42,0.14-0.93,0.24-1.52c0.1-0.59,0.2-1.24,0.31-1.95c0.11-0.71,0.21-1.41,0.3-2.1
			c0.09-0.69,0.18-1.34,0.24-1.94c0.06-0.6,0.1-1.1,0.1-1.49c0-0.65,0.06-1.15,0.19-1.52c0.13-0.36,0.27-0.64,0.44-0.83
			c0.19-0.22,0.41-0.36,0.65-0.42c0.48-0.17,0.94-0.32,1.37-0.48c0.37-0.14,0.75-0.28,1.13-0.42c0.02-0.01,0.03-0.01,0.05-0.02
			c-0.16-0.46-0.3-0.96-0.39-1.51c-0.1-0.63-0.22-1.25-0.36-1.87c-0.09-0.46-0.2-0.74-0.33-0.84c-0.13-0.1-0.28-0.09-0.44,0.05
			c-0.16,0.13-0.33,0.36-0.5,0.67c-0.17,0.31-0.35,0.65-0.52,1.03c-0.17,0.38-0.34,0.75-0.51,1.13c-0.17,0.38-0.32,0.71-0.46,1
			c-0.14,0.42-0.33,0.84-0.56,1.25c-0.24,0.41-0.52,0.77-0.87,1.06c-0.18,0.15-0.38,0.28-0.59,0.38c-0.01,0.05-0.02,0.09-0.04,0.14
			c-0.19,0.53-0.42,0.95-0.69,1.26s-0.55,0.53-0.85,0.68c-0.3,0.15-0.6,0.23-0.91,0.27c-0.3,0.03-0.6,0.03-0.89,0
			c-0.29-0.03-0.55-0.07-0.78-0.11c-0.24-0.05-0.42-0.07-0.56-0.07c-0.1-0.05-0.24-0.12-0.41-0.2c-0.17-0.08-0.37-0.14-0.6-0.17
			c-0.23-0.03-0.49-0.02-0.77,0.04c-0.29,0.06-0.59,0.19-0.9,0.4c-0.31,0.21-0.64,0.52-0.97,0.93c-0.34,0.41-0.68,0.96-1.02,1.65
			c-0.29,0.74-0.4,1.38-0.33,1.93c0.07,0.55,0.25,1.02,0.54,1.4c0.29,0.39,0.66,0.71,1.09,0.95c0.43,0.25,0.86,0.45,1.29,0.59
			c0.13,0.04,0.24,0.08,0.36,0.11c-0.05-0.38-0.05-0.75,0.16-1.05c0.44-0.61,1.34-0.74,1.54-1.58c0.04-0.18,0-0.42,0.18-0.37
			c0.13,0.03,0.22,0.34,0.25,0.46c0.08,0.36,0.08,0.73-0.08,1.05c-0.16,0.32-0.42,0.65-0.42,1.04c0,0.37,0.38,0.71,0.64,0.87
			c0.33,0.2,0.54,0.11,0.62-0.31c0.04-0.18,0.04-0.37-0.02-0.54c-0.02-0.07-0.09-0.15-0.11-0.23c-0.02-0.08,0.08-0.14,0.12-0.07
			c0.25,0.39,0.47,0.7,0.58,1.19c0.05,0.21,0.05,0.43,0.05,0.65c0.04,0.03,0.07,0.05,0.11,0.08c0.28,0.22,0.5,0.51,0.67,0.86
			c0.17,0.35,0.24,0.76,0.23,1.24c-0.01,0.48-0.17,1.03-0.46,1.67c-0.29,0.69-0.63,1.21-1.02,1.57c-0.39,0.36-0.8,0.59-1.24,0.72
			c-0.44,0.12-0.89,0.16-1.35,0.11c-0.46-0.04-0.9-0.13-1.33-0.25c-0.43-0.12-0.82-0.25-1.18-0.39c-0.36-0.14-0.67-0.24-0.91-0.29
			c-0.18-0.08-0.45-0.25-0.81-0.51c-0.36-0.27-0.76-0.62-1.19-1.05c-0.43-0.43-0.88-0.94-1.33-1.53c-0.46-0.59-0.88-1.24-1.25-1.96
			c-0.38-0.72-0.69-1.49-0.93-2.32c-0.24-0.83-0.37-1.7-0.38-2.61c-0.01-0.91,0.12-1.86,0.39-2.84c0.27-0.99,0.74-2,1.4-3.03
			c0.73-0.99,1.46-1.8,2.19-2.42c0.73-0.62,1.44-1.09,2.13-1.43c0.69-0.34,1.35-0.56,1.99-0.66c0.64-0.1,1.23-0.13,1.78-0.08
			c0.43,0.04,0.85,0.12,1.27,0.23c0.12-0.42,0.34-0.82,0.67-1.21c0.17-0.24,0.37-0.66,0.61-1.25c0.24-0.59,0.5-1.29,0.78-2.09
			c0.28-0.8,0.57-1.68,0.86-2.63c0.29-0.95,0.59-1.9,0.88-2.85c0.66-2.24,1.36-4.7,2.1-7.39c0.06-0.19,0.12-0.38,0.21-0.55
			c-0.01-0.01-0.02-0.02-0.03-0.03c0.04-0.42-0.02-0.85,0.07-1.27c0.12-0.51,0.35-0.84,0.61-1.25c0.05-0.07,0.15-0.02,0.13,0.07
			c-0.02,0.08-0.09,0.18-0.11,0.24c-0.06,0.18-0.06,0.38-0.02,0.57c0.09,0.43,0.31,0.53,0.65,0.32c0.27-0.16,0.67-0.53,0.67-0.91
			c0-0.41-0.28-0.76-0.44-1.09c-0.17-0.34-0.17-0.72-0.09-1.1c0.03-0.13,0.13-0.45,0.26-0.49c0.19-0.05,0.15,0.2,0.19,0.39
			c0.21,0.88,1.15,1.01,1.61,1.65c0.48,0.67-0.07,1.65,0.1,2.4c0.15,0.2,0.29,0.41,0.4,0.65c0.19,0.39,0.34,0.82,0.46,1.28
			c0.12,0.46,0.21,0.89,0.29,1.3c0.04,0.23,0.12,0.67,0.24,1.31c0.12,0.64,0.26,1.4,0.42,2.28c0.16,0.88,0.34,1.83,0.53,2.84
			c0.2,1.02,0.4,2.02,0.61,3c0.21,0.98,0.42,1.9,0.62,2.75s0.4,1.55,0.59,2.1c0.27,0.76,0.43,1.41,0.5,1.94
			c0.06,0.53,0.02,0.97-0.13,1.31c-0.04,0.08-0.08,0.16-0.14,0.24c0.01,0.07,0.03,0.13,0.04,0.2c0,0.58-0.1,1.06-0.3,1.43
			c-0.2,0.37-0.45,0.67-0.75,0.89c-0.31,0.22-0.65,0.4-1.03,0.52c-0.38,0.13-0.76,0.24-1.13,0.34c-0.42,0.11-0.76,0.23-1.02,0.33
			c-0.26,0.11-0.47,0.27-0.63,0.48c-0.16,0.21-0.29,0.5-0.38,0.88c-0.09,0.37-0.19,0.88-0.3,1.52c-0.1,0.64-0.09,1.14,0.03,1.51
			c0.12,0.37,0.3,0.65,0.53,0.83c0.23,0.18,0.47,0.3,0.72,0.35c0.25,0.05,0.46,0.08,0.63,0.08c0.16,0,0.37,0.02,0.61,0.06
			c0.24,0.04,0.47,0.13,0.7,0.26c0.22,0.13,0.41,0.32,0.57,0.57c0.16,0.25,0.24,0.58,0.24,1c0,0.37-0.11,0.7-0.34,0.99
			c-0.23,0.29-0.51,0.53-0.85,0.72s-0.69,0.32-1.05,0.39c-0.36,0.08-0.68,0.09-0.94,0.04c-0.27-0.05-0.54-0.09-0.81-0.13
			c-0.27-0.03-0.52,0.01-0.75,0.13c-0.22,0.12-0.41,0.34-0.55,0.67c-0.15,0.33-0.22,0.81-0.22,1.44c0,0.38,0.1,0.67,0.3,0.87
			c0.2,0.2,0.47,0.32,0.79,0.37c0.33,0.05,0.69,0.04,1.09-0.02c0.4-0.06,0.8-0.15,1.18-0.27c0.39-0.12,0.75-0.24,1.1-0.37
			c0.34-0.13,0.62-0.26,0.84-0.37c0.27-0.22,0.56-0.33,0.89-0.33c0.32-0.01,0.62,0.09,0.9,0.28c0.27,0.19,0.51,0.47,0.7,0.83
			c0.19,0.36,0.29,0.81,0.29,1.35C56.3,76.55,56.26,76.9,56.19,77.21z M67.72,68.98C67.71,68.99,67.7,69,67.7,69
			c0.02,0.09,0.03,0.2,0.01,0.31c-0.05,0.3-0.16,0.59-0.32,0.88c-0.16,0.29-0.33,0.54-0.52,0.77c-0.19,0.23-0.31,0.36-0.34,0.4
			c-0.1,0.1-0.33,0.28-0.67,0.53c-0.34,0.25-0.8,0.53-1.36,0.83c-0.56,0.31-1.22,0.61-1.98,0.91c-0.76,0.3-1.6,0.54-2.53,0.74
			c-0.84,0-1.53-0.08-2.08-0.24c-0.55-0.16-0.99-0.37-1.32-0.62c-0.33-0.26-0.57-0.54-0.73-0.86c-0.15-0.32-0.25-0.64-0.31-0.96
			c-0.05-0.32-0.07-0.62-0.06-0.9c0.01-0.28,0.02-0.52,0.02-0.71c-0.03-0.11-0.01-0.39,0.04-0.82c0.05-0.43,0.13-0.96,0.23-1.58
			c0.1-0.62,0.22-1.31,0.35-2.07c0.13-0.76,0.28-1.52,0.43-2.3c0.33-1.82,0.72-3.85,1.18-6.09c0.09-0.61,0.27-1.08,0.54-1.39
			c0.27-0.32,0.57-0.52,0.9-0.61s0.65-0.08,0.96,0.02c0.32,0.1,0.57,0.27,0.75,0.5c0.19,0.28,0.32,0.63,0.4,1.04
			s0.11,0.84,0.11,1.28c0,0.44-0.03,0.87-0.09,1.28c-0.06,0.42-0.14,0.76-0.23,1.04c-0.05,0.33-0.1,0.67-0.15,1.03
			c-0.05,0.36-0.12,0.79-0.22,1.29c-0.1,0.5-0.22,1.1-0.36,1.8c-0.15,0.7-0.33,1.57-0.57,2.6c-0.19,0.69-0.22,1.2-0.09,1.53
			c0.13,0.33,0.34,0.54,0.65,0.63c0.31,0.09,0.68,0.09,1.12,0.01c0.44-0.08,0.88-0.19,1.31-0.33c0.43-0.14,0.84-0.28,1.21-0.43
			c0.37-0.14,0.66-0.24,0.85-0.28c0.23-0.04,0.44-0.08,0.64-0.11c0.2-0.04,0.4-0.05,0.59-0.05c0.17,0,0.34,0.04,0.52,0.1
			c0.06-0.08,0.08-0.21,0.07-0.4c-0.02-0.21-0.28-0.42-0.12-0.46c0.14-0.04,0.35,0.08,0.46,0.16c0.39,0.26,0.58,0.43,0.68,0.94
			C67.72,68.58,67.74,68.78,67.72,68.98z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M36,87.76c0.21-0.76,0.31-1.48,0.3-2.14c-0.01-0.66-0.11-1.26-0.28-1.79c-0.17-0.53-0.36-0.95-0.56-1.25
			c-0.22-0.32-0.47-0.55-0.75-0.7c-0.29-0.15-0.56-0.25-0.81-0.32c-0.3-0.06-0.62-0.08-0.94-0.06c-0.47,0.12-0.86,0.38-1.16,0.79
			c-0.3,0.41-0.55,0.88-0.75,1.4c-0.19,0.52-0.35,1.04-0.47,1.57c-0.12,0.52-0.23,0.94-0.33,1.24c-0.22,0.75-0.27,1.46-0.16,2.14
			c0.11,0.67,0.37,1.21,0.77,1.62c0.2,0.25,0.45,0.46,0.73,0.63c0.29,0.17,0.6,0.25,0.93,0.27c0.34,0.01,0.7-0.07,1.1-0.23
			c0.39-0.17,0.8-0.46,1.22-0.88C35.4,89.3,35.79,88.53,36,87.76z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M61.03,38.31c-0.48,0-0.87,0.12-1.15,0.37c-0.28,0.25-0.42,0.66-0.42,1.23c0,0.11,0.04,0.25,0.12,0.42
			c0.08,0.17,0.18,0.33,0.31,0.49c0.13,0.16,0.3,0.3,0.49,0.41c0.19,0.12,0.41,0.17,0.65,0.17c0.13,0,0.25-0.06,0.38-0.17
			c0.13-0.11,0.24-0.25,0.34-0.41c0.1-0.16,0.18-0.32,0.24-0.49c0.06-0.17,0.09-0.31,0.09-0.42c0-0.57-0.11-0.98-0.33-1.23
			C61.54,38.44,61.29,38.31,61.03,38.31z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M34.01,34.88c-0.87,0-1.3,0.45-1.3,1.34c0,0.2,0.11,0.46,0.33,0.79c0.22,0.32,0.55,0.48,0.97,0.48
			c0.22,0,0.42-0.16,0.6-0.48c0.18-0.32,0.27-0.58,0.27-0.79c0-0.44-0.09-0.78-0.27-1C34.43,34.99,34.23,34.88,34.01,34.88z"
          />
          <path
            className="svg-logo-yellow"
            fill="#F7D713"
            d="M34.15,49.42c-0.18,0.22-0.26,0.57-0.26,1.05c0,0.24,0.09,0.53,0.26,0.87c0.18,0.34,0.4,0.52,0.66,0.52
			c0.48,0,0.82-0.17,1.03-0.52c0.22-0.34,0.32-0.63,0.32-0.87c0-0.92-0.45-1.38-1.35-1.38C34.55,49.08,34.33,49.19,34.15,49.42z"
          />
        </g>
        <g>
          <g>
            <path
              className="svg-logo-red"
              fill="#F11F20"
              d="M68.96,97.06c-0.06-0.53-0.23-1.17-0.5-1.94c-0.19-0.55-0.39-1.25-0.59-2.1
				c-0.2-0.85-0.41-1.77-0.62-2.75c-0.21-0.98-0.41-1.98-0.61-3c-0.2-1.02-0.38-1.97-0.53-2.84c-0.16-0.88-0.3-1.64-0.42-2.28
				c-0.12-0.64-0.2-1.08-0.24-1.31c-0.08-0.41-0.17-0.84-0.29-1.3c-0.11-0.46-0.27-0.88-0.46-1.28c-0.19-0.4-0.43-0.73-0.72-1.01
				c-0.29-0.28-0.63-0.46-1.04-0.54c-0.2-0.03-0.38-0.02-0.55-0.01c0.08-0.16,0.18-0.31,0.25-0.45c0.17-0.34,0.17-0.72,0.09-1.09
				c-0.03-0.13-0.13-0.46-0.26-0.49c-0.19-0.05-0.15,0.2-0.19,0.39c-0.21,0.88-1.16,1.01-1.61,1.65c-0.34,0.47-0.16,1.1-0.09,1.69
				c-0.03,0.08-0.07,0.16-0.09,0.24c-0.74,2.68-1.44,5.15-2.1,7.39c-0.29,0.95-0.59,1.91-0.88,2.85s-0.58,1.82-0.86,2.63
				s-0.54,1.5-0.78,2.09c-0.24,0.59-0.44,1.01-0.61,1.25c-0.41,0.48-0.66,0.99-0.74,1.52c-0.09,0.53-0.06,1.04,0.09,1.52
				c0.15,0.48,0.41,0.92,0.76,1.31c0.35,0.39,0.78,0.68,1.26,0.89c0.55,0.2,1.05,0.25,1.5,0.15c0.45-0.1,0.85-0.3,1.19-0.59
				c0.34-0.29,0.63-0.65,0.87-1.06c0.23-0.41,0.42-0.83,0.56-1.25c0.14-0.28,0.29-0.61,0.46-0.99c0.16-0.38,0.33-0.76,0.5-1.14
				c0.17-0.38,0.34-0.72,0.51-1.03c0.17-0.31,0.34-0.53,0.5-0.67c0.16-0.13,0.3-0.15,0.44-0.05c0.13,0.1,0.24,0.38,0.33,0.84
				c0.14,0.63,0.26,1.25,0.36,1.87c0.1,0.62,0.25,1.18,0.44,1.68c0.19,0.49,0.46,0.9,0.79,1.21c0.34,0.31,0.82,0.47,1.44,0.47
				c0.61-0.06,1.11-0.19,1.49-0.37c0.38-0.19,0.65-0.45,0.8-0.79C68.98,98.03,69.03,97.59,68.96,97.06z M63.54,88.69
				c-0.03,0.18-0.08,0.34-0.16,0.48c-0.08,0.14-0.23,0.21-0.43,0.21c-0.14,0-0.25-0.05-0.32-0.14c-0.07-0.09-0.12-0.21-0.15-0.35
				c-0.03-0.14-0.05-0.29-0.06-0.45c-0.01-0.16-0.01-0.31-0.01-0.45v-1.38c-0.01-0.14,0.01-0.27,0.06-0.4
				c0.03-0.1,0.08-0.2,0.15-0.29s0.19-0.13,0.33-0.13c0.14,0,0.23,0.02,0.28,0.05c0.05,0.03,0.07,0.07,0.07,0.11
				c0,0.05-0.02,0.09-0.06,0.13l0.27,1.91C63.55,88.25,63.57,88.49,63.54,88.69z"
            />
          </g>
          <g>
            <path
              className="svg-logo-red"
              fill="#F11F20"
              d="M39.94,84.22c-0.16-0.93-0.37-1.82-0.6-2.67c-0.24-0.85-0.57-1.62-1-2.3c-0.43-0.68-0.99-1.24-1.66-1.68
				c-0.67-0.44-1.53-0.72-2.56-0.83c-0.99-0.1-1.85-0.03-2.56,0.23c-0.71,0.25-1.31,0.57-1.78,0.95c-0.04,0.03-0.08,0.06-0.11,0.1
				c-0.23,0.07-0.39-0.06-0.46-0.41c-0.04-0.19-0.04-0.39,0.02-0.57c0.02-0.07,0.09-0.16,0.11-0.24c0.02-0.09-0.08-0.14-0.13-0.07
				c-0.26,0.41-0.49,0.73-0.61,1.24c-0.11,0.47-0.02,0.97-0.1,1.44c-0.12,0.21-0.2,0.36-0.23,0.46c-0.01,0.03-0.03,0.06-0.04,0.09
				c-0.15,0.16-0.35,0.27-0.53,0.23c-0.42-0.11-0.08-0.76-0.25-0.96c-0.03-0.03-0.08-0.02-0.09,0.02c-0.19,0.42-0.46,0.84-0.46,1.32
				c0,0.45,0.1,0.89,0.13,1.33c0,0,0,0.01,0,0.02c0.09,0.13,0.18,0.25,0.28,0.36c-0.16,0.5-0.3,1-0.42,1.51
				c-0.13,0.56-0.22,1.15-0.29,1.79c-0.06,0.63-0.07,1.27-0.01,1.92c0.06,0.65,0.18,1.29,0.38,1.94c0.2,0.64,0.49,1.26,0.89,1.86
				c0.51,0.76,1.02,1.37,1.54,1.82c0.51,0.45,1.02,0.78,1.53,0.99c0.5,0.21,0.99,0.32,1.48,0.33c0.48,0.01,0.94-0.05,1.38-0.16
				c0.44-0.12,0.86-0.28,1.27-0.48c0.41-0.2,0.78-0.41,1.13-0.61c0.57-0.31,1.12-0.77,1.63-1.38c0.52-0.62,0.96-1.33,1.32-2.14
				c0.36-0.81,0.63-1.68,0.78-2.61C40.09,86.1,40.09,85.17,39.94,84.22z M36.11,87.76c-0.21,0.76-0.59,1.53-1.15,2.31
				c-0.42,0.42-0.83,0.71-1.22,0.88c-0.39,0.17-0.76,0.24-1.1,0.23c-0.34-0.01-0.65-0.1-0.94-0.27c-0.29-0.17-0.53-0.38-0.73-0.63
				c-0.41-0.41-0.67-0.95-0.78-1.62s-0.05-1.39,0.16-2.14c0.1-0.3,0.21-0.72,0.33-1.24c0.12-0.52,0.28-1.04,0.47-1.56
				c0.19-0.52,0.44-0.99,0.75-1.4c0.31-0.41,0.69-0.68,1.17-0.79c0.32-0.03,0.63,0,0.94,0.06c0.25,0.07,0.52,0.17,0.81,0.32
				c0.29,0.15,0.54,0.38,0.75,0.7c0.2,0.31,0.39,0.72,0.56,1.25c0.17,0.53,0.26,1.12,0.28,1.79C36.42,86.28,36.32,86.99,36.11,87.76
				z"
            />
          </g>
          <path
            className="svg-logo-red"
            fill="#F11F20"
            d="M67.8,68.38c-0.1-0.51-0.29-0.68-0.68-0.94c-0.11-0.07-0.32-0.2-0.46-0.16c-0.16,0.04,0.11,0.25,0.12,0.46
			c0.02,0.19-0.01,0.32-0.07,0.4c-0.18-0.06-0.35-0.1-0.52-0.1c-0.2-0.01-0.39,0.01-0.59,0.05c-0.2,0.04-0.41,0.07-0.64,0.11
			c-0.19,0.04-0.47,0.13-0.85,0.28c-0.38,0.15-0.78,0.29-1.21,0.43c-0.43,0.14-0.87,0.25-1.31,0.33c-0.44,0.08-0.81,0.08-1.12-0.01
			c-0.3-0.09-0.52-0.3-0.65-0.63c-0.13-0.33-0.1-0.84,0.09-1.53c0.23-1.03,0.42-1.9,0.56-2.6c0.15-0.7,0.26-1.3,0.36-1.8
			c0.09-0.5,0.17-0.93,0.22-1.29c0.05-0.36,0.1-0.7,0.15-1.03c0.09-0.28,0.17-0.63,0.23-1.04c0.06-0.41,0.09-0.84,0.09-1.28
			c0-0.44-0.04-0.87-0.11-1.28c-0.08-0.41-0.21-0.76-0.4-1.04c-0.18-0.23-0.43-0.39-0.74-0.5c-0.32-0.1-0.64-0.11-0.96-0.02
			c-0.32,0.09-0.62,0.29-0.9,0.61c-0.27,0.32-0.46,0.78-0.54,1.39c-0.46,2.24-0.85,4.27-1.19,6.09c-0.15,0.78-0.3,1.54-0.43,2.3
			c-0.13,0.76-0.25,1.45-0.35,2.07c-0.1,0.63-0.18,1.15-0.23,1.58c-0.05,0.43-0.06,0.71-0.04,0.82c0,0.19-0.01,0.43-0.02,0.71
			c-0.01,0.28,0.01,0.58,0.06,0.9c0.05,0.32,0.15,0.64,0.31,0.96c0.15,0.32,0.39,0.6,0.73,0.86c0.33,0.25,0.77,0.46,1.32,0.62
			c0.55,0.16,1.24,0.24,2.08,0.24c0.93-0.19,1.77-0.44,2.53-0.74c0.76-0.3,1.42-0.6,1.98-0.91c0.56-0.3,1.01-0.58,1.36-0.83
			c0.34-0.25,0.56-0.42,0.67-0.53c0.04-0.04,0.15-0.17,0.34-0.4c0.19-0.23,0.36-0.49,0.52-0.77c0.16-0.29,0.26-0.58,0.32-0.88
			c0.02-0.11,0.01-0.21-0.01-0.31c0.01-0.01,0.02-0.01,0.03-0.02C67.85,68.77,67.83,68.58,67.8,68.38z"
          />
          <g>
            <path
              className="svg-logo-red"
              fill="#F11F20"
              d="M56.42,76.13c0-0.54-0.1-0.98-0.29-1.35c-0.19-0.36-0.42-0.64-0.7-0.83c-0.27-0.19-0.57-0.28-0.9-0.28
				c-0.33,0.01-0.62,0.12-0.89,0.33c-0.22,0.12-0.5,0.24-0.84,0.37c-0.34,0.13-0.71,0.26-1.1,0.37s-0.78,0.21-1.18,0.27
				c-0.4,0.06-0.76,0.07-1.09,0.02c-0.33-0.05-0.59-0.18-0.79-0.37c-0.2-0.2-0.31-0.49-0.31-0.87c0-0.64,0.07-1.12,0.22-1.44
				c0.15-0.32,0.33-0.55,0.55-0.67c0.22-0.12,0.47-0.17,0.74-0.13c0.28,0.03,0.55,0.07,0.81,0.13c0.27,0.05,0.58,0.04,0.94-0.04
				c0.36-0.08,0.71-0.21,1.05-0.39c0.34-0.19,0.62-0.42,0.85-0.72c0.23-0.29,0.34-0.62,0.34-0.99c0-0.42-0.08-0.76-0.24-1
				c-0.16-0.25-0.35-0.44-0.57-0.57c-0.22-0.13-0.46-0.22-0.7-0.26c-0.24-0.04-0.45-0.06-0.61-0.06c-0.17,0-0.38-0.03-0.63-0.08
				c-0.25-0.05-0.49-0.17-0.72-0.35c-0.23-0.18-0.4-0.46-0.53-0.83c-0.12-0.37-0.13-0.87-0.03-1.51c0.1-0.64,0.2-1.14,0.29-1.52
				c0.1-0.38,0.22-0.67,0.38-0.88c0.16-0.21,0.37-0.37,0.63-0.48c0.26-0.11,0.6-0.22,1.02-0.33c0.37-0.1,0.75-0.22,1.12-0.34
				c0.38-0.13,0.73-0.3,1.03-0.52c0.3-0.22,0.56-0.52,0.75-0.89c0.2-0.37,0.3-0.85,0.3-1.43c-0.01-0.07-0.03-0.14-0.04-0.2
				c0.05-0.08,0.1-0.16,0.13-0.24c0.15-0.34,0.2-0.78,0.13-1.31c-0.06-0.53-0.23-1.17-0.5-1.94c-0.19-0.55-0.39-1.25-0.59-2.1
				c-0.21-0.85-0.41-1.77-0.62-2.75c-0.21-0.98-0.41-1.98-0.61-3c-0.2-1.02-0.37-1.97-0.53-2.84c-0.16-0.88-0.3-1.64-0.42-2.28
				c-0.12-0.64-0.2-1.08-0.24-1.31c-0.07-0.41-0.17-0.84-0.29-1.3c-0.11-0.46-0.27-0.88-0.46-1.28c-0.12-0.24-0.25-0.45-0.4-0.65
				c-0.17-0.75,0.38-1.73-0.1-2.4c-0.46-0.64-1.4-0.78-1.61-1.65c-0.05-0.18,0-0.44-0.19-0.39c-0.14,0.03-0.23,0.35-0.26,0.49
				c-0.08,0.37-0.08,0.76,0.09,1.1c0.17,0.33,0.44,0.68,0.44,1.09c0,0.39-0.39,0.75-0.67,0.91c-0.34,0.21-0.57,0.11-0.65-0.32
				c-0.04-0.19-0.04-0.39,0.02-0.57c0.02-0.07,0.09-0.16,0.11-0.25c0.02-0.09-0.08-0.14-0.13-0.07c-0.26,0.41-0.49,0.73-0.61,1.24
				c-0.1,0.42-0.04,0.85-0.07,1.27c0.01,0.01,0.02,0.02,0.03,0.03c-0.08,0.17-0.15,0.35-0.2,0.55c-0.74,2.69-1.44,5.15-2.1,7.39
				c-0.29,0.96-0.59,1.91-0.88,2.86c-0.29,0.95-0.58,1.82-0.86,2.62c-0.28,0.8-0.54,1.5-0.78,2.09c-0.24,0.59-0.44,1.01-0.61,1.25
				c-0.33,0.39-0.55,0.79-0.67,1.21c-0.42-0.11-0.84-0.2-1.27-0.23c-0.55-0.05-1.14-0.03-1.78,0.08c-0.64,0.1-1.3,0.32-1.99,0.66
				c-0.69,0.34-1.4,0.81-2.13,1.43c-0.73,0.62-1.46,1.42-2.19,2.42c-0.66,1.03-1.13,2.04-1.4,3.03c-0.28,0.99-0.41,1.94-0.39,2.84
				c0.01,0.91,0.14,1.78,0.38,2.61c0.24,0.83,0.55,1.6,0.93,2.32c0.38,0.72,0.79,1.37,1.25,1.96c0.46,0.58,0.9,1.09,1.34,1.53
				c0.43,0.43,0.83,0.78,1.19,1.05c0.36,0.27,0.63,0.44,0.81,0.51c0.24,0.05,0.55,0.15,0.91,0.29c0.36,0.14,0.76,0.27,1.18,0.39
				s0.87,0.2,1.33,0.25c0.46,0.04,0.91,0.01,1.34-0.11c0.44-0.12,0.85-0.36,1.24-0.72c0.39-0.36,0.73-0.88,1.02-1.57
				c0.29-0.64,0.45-1.19,0.46-1.67c0.01-0.48-0.06-0.89-0.23-1.24c-0.17-0.35-0.39-0.64-0.67-0.86c-0.04-0.03-0.07-0.05-0.11-0.08
				c0-0.22,0-0.43-0.05-0.65c-0.11-0.49-0.33-0.8-0.58-1.19c-0.04-0.07-0.14-0.02-0.12,0.07c0.02,0.08,0.09,0.17,0.11,0.23
				c0.05,0.17,0.05,0.37,0.02,0.54c-0.08,0.41-0.3,0.5-0.62,0.31c-0.26-0.15-0.64-0.5-0.64-0.87c0-0.39,0.27-0.72,0.42-1.04
				c0.16-0.32,0.16-0.69,0.08-1.05c-0.03-0.13-0.12-0.43-0.25-0.46c-0.18-0.05-0.14,0.19-0.18,0.37c-0.2,0.84-1.1,0.97-1.54,1.58
				c-0.21,0.3-0.21,0.67-0.16,1.05c-0.12-0.04-0.23-0.07-0.36-0.11c-0.43-0.15-0.86-0.34-1.29-0.59c-0.43-0.25-0.8-0.57-1.09-0.95
				c-0.29-0.39-0.48-0.86-0.54-1.4c-0.07-0.55,0.04-1.19,0.33-1.93c0.34-0.69,0.69-1.24,1.02-1.65c0.34-0.41,0.66-0.73,0.98-0.93
				c0.31-0.21,0.61-0.34,0.9-0.4c0.29-0.06,0.54-0.07,0.77-0.04c0.23,0.03,0.43,0.09,0.6,0.17c0.17,0.08,0.31,0.15,0.41,0.2
				c0.14,0,0.33,0.02,0.56,0.07c0.24,0.04,0.5,0.08,0.78,0.11c0.29,0.03,0.58,0.03,0.89,0c0.3-0.03,0.61-0.12,0.91-0.27
				c0.3-0.15,0.58-0.37,0.85-0.68c0.27-0.3,0.5-0.73,0.69-1.26c0.02-0.05,0.02-0.09,0.04-0.14c0.21-0.1,0.41-0.23,0.59-0.38
				c0.34-0.29,0.63-0.65,0.87-1.06c0.23-0.41,0.42-0.83,0.56-1.25c0.14-0.28,0.29-0.61,0.46-0.99c0.16-0.38,0.33-0.76,0.51-1.14
				s0.34-0.72,0.52-1.03c0.17-0.31,0.34-0.54,0.5-0.67c0.16-0.13,0.3-0.15,0.44-0.05c0.13,0.1,0.25,0.38,0.33,0.84
				c0.14,0.62,0.26,1.25,0.36,1.87c0.09,0.56,0.22,1.06,0.39,1.51c-0.02,0.01-0.03,0.01-0.05,0.02c-0.38,0.14-0.76,0.28-1.13,0.42
				c-0.43,0.15-0.89,0.31-1.38,0.48c-0.24,0.07-0.46,0.2-0.65,0.42c-0.17,0.19-0.31,0.47-0.44,0.83c-0.13,0.36-0.19,0.87-0.19,1.52
				c0,0.39-0.03,0.89-0.1,1.49c-0.06,0.6-0.15,1.25-0.24,1.94c-0.09,0.69-0.2,1.39-0.3,2.1c-0.11,0.71-0.21,1.36-0.31,1.95
				c-0.1,0.59-0.17,1.1-0.24,1.52c-0.06,0.42-0.09,0.68-0.09,0.78c-0.04,0.1-0.11,0.32-0.2,0.65c-0.1,0.33-0.17,0.72-0.23,1.16
				c-0.06,0.44-0.07,0.93-0.05,1.45s0.14,1.02,0.34,1.51c0.2,0.49,0.52,0.93,0.94,1.33c0.42,0.4,1,0.71,1.76,0.92
				c0.61,0.08,1.22,0.16,1.81,0.25c0.39,0.06,0.79,0.13,1.2,0.2c0,0.27,0,0.55,0.02,0.82c0,0.54,0.06,1.11,0.18,1.72
				c0.12,0.61,0.22,1.19,0.3,1.74c0.08,0.55,0.1,1.02,0.06,1.42c-0.05,0.4-0.23,0.65-0.55,0.75c-0.37,0.11-0.7,0.11-0.99-0.02
				c-0.29-0.13-0.56-0.31-0.8-0.56s-0.45-0.52-0.63-0.82c-0.18-0.3-0.33-0.56-0.46-0.79c-0.09-0.18-0.24-0.46-0.45-0.83
				c-0.21-0.38-0.46-0.79-0.75-1.24c-0.29-0.45-0.6-0.92-0.93-1.4c-0.34-0.49-0.68-0.93-1.02-1.33c-0.34-0.4-0.69-0.74-1.02-1.01
				c-0.34-0.27-0.65-0.42-0.93-0.45c-0.36-0.06-0.67,0.01-0.93,0.23c-0.27,0.22-0.49,0.52-0.67,0.9c-0.18,0.38-0.31,0.82-0.4,1.31
				c-0.09,0.49-0.13,0.97-0.13,1.44c0.02,1.36,0.05,2.63,0.08,3.8c0.01,0.5,0.02,0.99,0.03,1.49c0.01,0.5,0.02,0.96,0.03,1.39
				c0.01,0.43,0.02,0.81,0.03,1.15c0.01,0.33,0.01,0.57,0.01,0.72c-0.03,0.26-0.05,0.57-0.08,0.94c-0.02,0.37-0.03,0.77-0.02,1.19
				c0.01,0.43,0.05,0.86,0.12,1.3c0.07,0.44,0.18,0.84,0.34,1.2c0.02,0.04,0.04,0.08,0.06,0.12c-0.02-0.3,0-0.58,0.17-0.82
				c0.41-0.57,1.25-0.69,1.44-1.48c0.04-0.16,0-0.39,0.17-0.35c0.12,0.03,0.21,0.32,0.23,0.44c0.08,0.33,0.08,0.68-0.08,0.98
				c-0.15,0.3-0.4,0.6-0.4,0.97c0,0.34,0.35,0.67,0.6,0.81c0.31,0.18,0.5,0.1,0.58-0.29c0.04-0.17,0.04-0.35-0.02-0.51
				c-0.02-0.06-0.08-0.14-0.1-0.22c-0.02-0.08,0.07-0.13,0.11-0.06c0.21,0.32,0.39,0.6,0.5,0.97c0.05-0.13,0.1-0.28,0.13-0.43
				c0.06-0.31,0.1-0.62,0.1-0.94c0.01-0.32,0-0.63-0.03-0.93c-0.03-0.31-0.05-0.57-0.08-0.8c0-0.17,0-0.42-0.01-0.76
				c0-0.34,0-0.73,0-1.14c0.01-0.42,0.02-0.85,0.03-1.3c0.01-0.44,0.04-0.86,0.07-1.24c0.04-0.38,0.09-0.7,0.16-0.96
				c0.07-0.26,0.16-0.41,0.28-0.45c0.27,0,0.54,0.08,0.83,0.23c0.29,0.15,0.57,0.35,0.84,0.59c0.27,0.24,0.53,0.51,0.78,0.8
				c0.25,0.29,0.46,0.58,0.64,0.86c0.15,0.22,0.32,0.48,0.5,0.78c0.18,0.3,0.37,0.62,0.58,0.95c0.21,0.33,0.44,0.66,0.68,0.97
				c0.24,0.32,0.51,0.6,0.79,0.85c0.29,0.25,0.6,0.44,0.93,0.58c0.34,0.14,0.7,0.19,1.1,0.15c0.62-0.05,1.08-0.22,1.37-0.51
				c0.28-0.29,0.47-0.62,0.56-1c0.09-0.38,0.12-0.77,0.09-1.16c-0.03-0.39-0.05-0.73-0.05-0.99c0-0.18,0-0.47,0.01-0.89
				c0.01-0.41,0.02-0.89,0.04-1.42c0.02-0.54,0.04-1.1,0.07-1.7c0.03-0.6,0.06-1.18,0.11-1.74c0.04-0.56,0.09-1.07,0.14-1.53
				c0.05-0.46,0.11-0.81,0.17-1.07c0.06-0.24,0.13-0.56,0.2-0.95c0.07-0.39,0.12-0.8,0.16-1.23c0.01-0.12,0.01-0.24,0.02-0.36
				C56.38,76.89,56.42,76.54,56.42,76.13z M50.18,48.36c-0.02,0.18-0.08,0.34-0.16,0.48c-0.08,0.14-0.22,0.21-0.43,0.21
				c-0.14,0-0.25-0.05-0.31-0.14c-0.07-0.1-0.12-0.21-0.15-0.35c-0.03-0.14-0.05-0.29-0.06-0.45c-0.01-0.16-0.01-0.31-0.01-0.45
				v-1.37c-0.01-0.14,0.01-0.27,0.06-0.4c0.03-0.1,0.08-0.2,0.15-0.29c0.08-0.09,0.19-0.13,0.33-0.13c0.14,0,0.23,0.02,0.28,0.05
				c0.04,0.03,0.07,0.07,0.07,0.1c0,0.05-0.02,0.09-0.06,0.13l0.27,1.91C50.19,47.92,50.2,48.16,50.18,48.36z"
            />
          </g>
          <g>
            <path
              className="svg-logo-red"
              fill="#F11F20"
              d="M42.92,49.84c-0.11-0.49-0.37-0.98-0.77-1.46c-0.41-0.48-0.98-0.94-1.72-1.37
				c-0.53-0.28-1.05-0.61-1.54-1c-0.49-0.39-0.93-0.8-1.32-1.24c-0.39-0.44-0.72-0.9-0.98-1.38c-0.27-0.48-0.44-0.95-0.52-1.42
				c-0.07-0.47-0.04-0.92,0.1-1.37c0.14-0.44,0.41-0.84,0.82-1.21c0.5-0.37,0.89-0.8,1.17-1.29c0.29-0.49,0.47-1,0.53-1.52
				c0.07-0.52,0.03-1.04-0.11-1.56c-0.14-0.52-0.39-1.01-0.76-1.45c-0.37-0.44-0.85-0.83-1.44-1.15c-0.59-0.32-1.29-0.54-2.11-0.67
				c-0.83-0.13-1.51-0.19-2.04-0.2c-0.53-0.01-0.97,0.02-1.3,0.1c-0.33,0.07-0.57,0.17-0.73,0.32c-0.16,0.14-0.26,0.29-0.32,0.46
				c-0.05,0.17-0.07,0.34-0.07,0.53c0.01,0.18,0.01,0.36,0.01,0.52c0.27,2.88,0.5,5.54,0.71,8c0.09,1.04,0.17,2.09,0.25,3.15
				c0.07,1.06,0.14,2.05,0.19,2.97c0,0.05,0.01,0.11,0.01,0.16c-1.11-0.76-0.5-1.72-0.92-1.44c-0.46,0.31-0.67,1.62-0.61,2.37
				c0.08,1.02,0.44,1.65,1.15,2.33c0.07,0.07,0.15,0.14,0.22,0.22c0.09,0.05,0.18,0.09,0.27,0.11c0,0.13-0.01,0.24-0.02,0.34
				c-0.08,0.5-0.06,0.9,0.04,1.21c0.1,0.31,0.26,0.56,0.48,0.76c0.21,0.19,0.47,0.33,0.75,0.42c0.29,0.09,0.57,0.15,0.85,0.19
				c0.28,0.04,0.54,0.06,0.78,0.08c0.24,0.01,0.43,0.04,0.56,0.08c0.16,0.04,0.42,0.07,0.77,0.09c0.35,0.02,0.75,0.02,1.2,0
				c0.45-0.02,0.93-0.06,1.45-0.13c0.52-0.07,1.02-0.18,1.51-0.32c0.49-0.14,0.95-0.32,1.38-0.54c0.43-0.22,0.79-0.5,1.07-0.83
				c0.43-0.43,0.73-0.89,0.91-1.38C42.99,50.81,43.02,50.33,42.92,49.84z M33.15,37c-0.22-0.32-0.33-0.59-0.33-0.79
				c0-0.89,0.43-1.34,1.3-1.34c0.22,0,0.42,0.11,0.6,0.34c0.18,0.22,0.27,0.56,0.27,1c0,0.2-0.09,0.46-0.27,0.79
				c-0.18,0.32-0.38,0.48-0.6,0.48C33.7,37.48,33.38,37.32,33.15,37z M35.95,51.33c-0.21,0.34-0.55,0.52-1.03,0.52
				c-0.26,0-0.48-0.17-0.66-0.52C34.09,50.98,34,50.69,34,50.46c0-0.47,0.09-0.82,0.26-1.05c0.18-0.23,0.4-0.34,0.66-0.34
				c0.9,0,1.35,0.46,1.35,1.38C36.27,50.69,36.17,50.98,35.95,51.33z"
            />
          </g>
          <g>
            <path
              className="svg-logo-red"
              fill="#F11F20"
              d="M65.79,39.7c0.09-0.11,0.22-0.2,0.35-0.29c0.38-0.25,0.59-0.5,0.71-0.95c0.09-0.33,0.1-0.94-0.08-1.12
				c-0.17-0.17,0.03,0.35-0.59,0.59c0-0.25-0.01-0.51-0.05-0.78c-0.12-0.74-0.32-1.4-0.62-1.99c-0.3-0.59-0.67-1.11-1.12-1.55
				c-0.45-0.44-0.96-0.8-1.55-1.08c-0.58-0.28-1.22-0.47-1.91-0.57c-0.63-0.06-1.2-0.07-1.72-0.01c-0.52,0.06-0.97,0.14-1.36,0.24
				c-0.45,0.12-0.85,0.26-1.22,0.42c-0.51,0.18-0.94,0.48-1.28,0.92c-0.15,0.19-0.29,0.41-0.42,0.67s-0.23,0.56-0.32,0.92
				c-0.08,0.36-0.13,0.76-0.14,1.2c-0.01,0.44,0.03,0.95,0.11,1.53c0.08,0.71,0.17,1.51,0.29,2.4c0.11,0.88,0.23,1.79,0.35,2.72
				c0.12,0.93,0.23,1.84,0.33,2.74c0.09,0.9,0.16,1.72,0.21,2.47c0.04,0.75,0.05,1.39,0.02,1.93c-0.03,0.53-0.12,0.89-0.28,1.07
				c-0.18,0.29-0.32,0.62-0.43,0.99c-0.11,0.37-0.13,0.73-0.06,1.09c0.07,0.35,0.25,0.66,0.56,0.95c0.11-0.34,0.27-0.59,0.46-0.88
				c0.04-0.06,0.12-0.01,0.1,0.06c-0.02,0.07-0.07,0.15-0.09,0.2c-0.05,0.15-0.05,0.32-0.02,0.47c0.07,0.36,0.26,0.44,0.54,0.27
				c0.23-0.14,0.55-0.44,0.56-0.76c0-0.34-0.23-0.63-0.37-0.91c-0.14-0.28-0.14-0.6-0.07-0.91c0.03-0.11,0.1-0.38,0.22-0.41
				c0.16-0.04,0.12,0.17,0.16,0.32c0.18,0.73,0.96,0.84,1.34,1.38c0.33,0.46,0.08,1.08,0.05,1.64c0.07-0.03,0.15-0.06,0.21-0.09
				c0.38-0.22,0.66-0.52,0.83-0.89c0.17-0.37,0.26-0.78,0.25-1.23c-0.01-0.45-0.06-0.88-0.16-1.29c-0.24-0.92-0.42-1.74-0.53-2.46
				c-0.11-0.72-0.17-1.26-0.17-1.6c0-0.1-0.01-0.23-0.03-0.38c-0.02-0.15-0.03-0.3-0.03-0.44c0-0.14,0.02-0.26,0.06-0.36
				c0.04-0.1,0.12-0.15,0.23-0.15c0.06,0,0.14,0.02,0.22,0.06c0.08,0.04,0.17,0.11,0.27,0.22c0.1,0.11,0.2,0.26,0.31,0.47
				c0.11,0.2,0.22,0.48,0.33,0.82c0.08,0.27,0.14,0.62,0.2,1.05c0.06,0.43,0.13,0.89,0.23,1.38c0.09,0.48,0.23,0.95,0.4,1.41
				c0.17,0.46,0.41,0.84,0.72,1.16c0.31,0.31,0.71,0.52,1.18,0.63c0.48,0.11,1.07,0.06,1.79-0.16c0.5-0.19,0.87-0.42,1.11-0.69
				c0.24-0.27,0.4-0.55,0.47-0.85c0.07-0.3,0.07-0.61-0.01-0.93c-0.08-0.32-0.19-0.62-0.33-0.92c-0.15-0.29-0.31-0.56-0.5-0.8
				c-0.18-0.24-0.35-0.44-0.5-0.59c-0.17-0.17-0.42-0.38-0.77-0.66c-0.34-0.27-0.69-0.57-1.03-0.9c-0.34-0.32-0.63-0.67-0.87-1.03
				c-0.23-0.36-0.33-0.72-0.27-1.06c0.11-0.38,0.24-0.7,0.38-0.96c0.14-0.26,0.28-0.48,0.42-0.65c0.14-0.17,0.28-0.31,0.42-0.4
				c0.14-0.09,0.27-0.17,0.38-0.22c0.1-0.05,0.31-0.2,0.63-0.46c0.32-0.25,0.63-0.59,0.95-1.02c0.03-0.04,0.05-0.08,0.08-0.12
				c0.14,0.01,0.31-0.06,0.48-0.19c-0.02-0.02-0.03-0.05-0.05-0.07c-0.07-0.12-0.09-0.23-0.08-0.33
				C65.72,39.86,65.76,39.78,65.79,39.7z M62.11,40.33c-0.06,0.17-0.14,0.33-0.24,0.49c-0.1,0.16-0.22,0.3-0.34,0.41
				c-0.13,0.12-0.26,0.17-0.38,0.17c-0.24,0-0.46-0.06-0.65-0.17c-0.19-0.11-0.35-0.25-0.49-0.41c-0.13-0.16-0.24-0.32-0.31-0.49
				c-0.08-0.17-0.12-0.31-0.12-0.42c0-0.57,0.14-0.98,0.42-1.23c0.28-0.25,0.66-0.37,1.15-0.37c0.27,0,0.51,0.12,0.73,0.37
				c0.22,0.25,0.33,0.66,0.33,1.23C62.19,40.02,62.16,40.16,62.11,40.33z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FlamesBarcelonaBadge;
