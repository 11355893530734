import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  showPasswordOne: false,
  showPasswordTwo: false,
  error: null
};

const ERROR_WEAK = "auth/weak-password";
const MSG_WEAK = "La contraseña debe tener un mínimo de 6 caracteres.";
//Password should be at least 6 characters

const ERROR_RECENT = "auth/requires-recent-login";
const MSG_RECENT =
  "Esta acción es sensible y requiere una autenticación reciente. Vuelve a iniciar sesión antes de intentarlo.";
//This operation is sensitive and requires recent authentication. Log in again before retrying this request.

class ChangePasswordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        if (error.code === ERROR_WEAK) {
          error.message = MSG_WEAK;
        } else if (error.code === ERROR_RECENT) {
          error.message = MSG_RECENT;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickShowPassword = prop => event => {
    this.setState({ [prop]: !this.state[prop] });
  };

  render() {
    const { classes } = this.props;
    const {
      passwordOne,
      showPasswordOne,
      passwordTwo,
      showPasswordTwo,
      error
    } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="adornment-passwordOne">
            Nueva contraseña
          </InputLabel>
          <Input
            id="adornment-passwordOne"
            type={showPasswordOne ? "text" : "password"}
            value={passwordOne}
            name="passwordOne"
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword("showPasswordOne")}
                >
                  {showPasswordOne ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && (
            <FormHelperText id="component-error-text">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="adornment-passwordTwo">
            Confirmar contraseña
          </InputLabel>
          <Input
            id="adornment-passwordTwo"
            type={showPasswordTwo ? "text" : "password"}
            value={passwordTwo}
            name="passwordTwo"
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword("showPasswordTwo")}
                >
                  {showPasswordTwo ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && (
            <FormHelperText id="component-error-text">
              {error.message} {error.code}
            </FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isInvalid}
          className={classes.submit}
        >
          Cambiar la contraseña
        </Button>
      </form>
    );
  }
}

const ChangePasswordForm = compose(
  withStyles(styles),
  withFirebase
)(ChangePasswordFormBase);

export default ChangePasswordForm;
