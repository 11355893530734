import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import CssBaseline from "@material-ui/core/CssBaseline";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: "Oswald"
    },
    h2: {
      fontFamily: "Oswald"
    },
    h3: {
      fontFamily: "Oswald"
    },
    h4: {
      fontFamily: "Oswald"
    },
    h5: {
      fontFamily: "Oswald"
    },
    h6: {
      fontFamily: "Oswald"
    }
  }
});

function withTheme(Component) {
  function withTheme(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return withTheme;
}

export default withTheme;
