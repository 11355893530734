import React, { Component } from "react";
import { compose } from "recompose";

import PasswordChangeForm from "../PasswordChange";
import {
  AuthUserContext,
  withAuthorization,
  withAuthentication
} from "../Session";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CheckIcon from "@material-ui/icons/Check";

import { Helmet } from "react-helmet";

import { withStyles } from "@material-ui/core/styles";

import Moment from "react-moment";
import "moment/locale/es";

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  bigAvatar: {
    margin: "30px auto",
    width: 200,
    height: 200
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  content: {
    //backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
    maxWidth: 600,
    padding: theme.spacing.unit * 6
  },
  /* Account page specific */
  inline: {
    display: "inline"
  },
  chip: {
    margin: theme.spacing.unit
  },
  chipContainer: {
    textAlign: "center",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1
  }
});

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      registrations: []
    };
  }

  componentDidMount() {
    if (this.state.registrations.empty) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .userRegistrations(this.props.authUser.uid)
      .onSnapshot(snapshot => {
        let registrations = [];

        snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), id: doc.id })
        );
        /*snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), id: doc.id + 1 })
        );
        snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), id: doc.id + 2 })
        );*/
        this.setState({
          registrations,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { loading, registrations } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        {/* Hero unit */}
        <AuthUserContext.Consumer>
          {authUser => (
            <main>
              <Helmet>
                <title>Perfil de {authUser.username}</title>
              </Helmet>
              <div className={classes.heroUnit}>
                <div className={classes.heroContent}>
                  <Avatar
                    alt={authUser.username}
                    src={authUser.photoURL}
                    className={classes.bigAvatar}
                  />
                  <Typography
                    component="h1"
                    variant="h3"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    {authUser.username}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="span"
                    gutterBottom
                  >
                    {authUser.email}{" "}
                    {authUser.emailVerified && <CheckIcon fontSize="small" />}
                  </Typography>

                  <div className={classes.chipContainer}>
                    {!authUser.roles.empty && (
                      <Typography variant="button" align="center" gutterBottom>
                        Roles
                      </Typography>
                    )}
                    {!authUser.roles.empty &&
                      authUser.roles.map((role, i) => (
                        <Chip
                          label={role}
                          className={classes.chip}
                          color="secondary"
                          key={i}
                        />
                      ))}
                  </div>

                  <div className={classes.chipContainer}>
                    {!authUser.providerData.empty && (
                      <Typography variant="button" align="center" gutterBottom>
                        Métodos de inicio de sesión
                      </Typography>
                    )}
                    {!authUser.providerData.empty &&
                      authUser.providerData.map((provider, i) => (
                        <React.Fragment key={i}>
                          <Chip
                            label={provider.providerId}
                            className={classes.chip}
                          />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </main>
          )}
        </AuthUserContext.Consumer>
        {/* End hero unit */}

        {/* Content */}
        <section className={classes.content}>
          <Typography variant="h6" align="center" gutterBottom>
            Inscripciones
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Tus últimas inscripciones
          </Typography>
          <List className={classes.root}>
            {registrations &&
              registrations.map(registration => (
                <ListItem alignItems="flex-start" key={registration.id}>
                  <ListItemAvatar>
                    <Avatar
                      alt={registration.eventName}
                      src={registration.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={registration.eventName}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          <Moment unix format="HH:mm" locale="es">
                            {registration.eventDate.seconds}
                          </Moment>
                          {" / "}
                          <Moment unix format="ll" locale="es">
                            {registration.eventDate.seconds}
                          </Moment>
                        </Typography>
                        {" — "}
                        {registration.eventSummary}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </section>

        <section className={classes.content}>
          <Typography variant="h6" align="center" gutterBottom>
            Cambiar la contraseña
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Introduce una nueva contraseña asociada a tu cuenta de usuario
          </Typography>
          <PasswordChangeForm />
        </section>

        {/*
        <section className={classes.content}>
          <Typography variant="h6" align="center" gutterBottom>
            Restablecer la contraseña
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Introduce el correo electrónico asociado a tu cuenta de usuario para
            recibir un enlace de restablecimiento de contraseña
          </Typography>
          <ForgotPasswordForm />
        </section>
        */}

        {/* End Content */}
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withAuthentication,
  withStyles(styles)
)(AccountPage);
