import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import EventPage from "../Event";
import AddEventPage from "../Add";

import ScrollToTop from "../Scroll";
import { Helmet } from "react-helmet";

import * as ROUTES from "../../constants/routes";

import { withAuthentication } from "../Session";

import Footer from "../Footer";

import withTheme from "../Theme/withTheme";

const App = () => (
  <Router>
    <React.Fragment>
      <Helmet>
        <title>Eventos de Flames Barcelona</title>
      </Helmet>
      <ScrollToTop>
        <Navigation />
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ABOUT} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.EVENT_DETAILS} component={EventPage} />
        <Route path={ROUTES.ADD_EVENT} component={AddEventPage} />
      </ScrollToTop>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>
  </Router>
);

export default withTheme(withAuthentication(App));
