import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";

import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";

import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

import ResponsiveTable from "material-ui-next-responsive-table";

import Moment from "react-moment";
import "moment/locale/es";

const styles = {
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
};

class RegistrationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      registrations: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .registrations()
      .onSnapshot(snapshot => {
        let registrations = [];

        snapshot.forEach(doc =>
          registrations.push({ ...doc.data(), uid: doc.id })
        );

        this.setState({
          registrations,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { registrations, loading } = this.state;
    const { classes } = this.props;

    const columns = [
      {
        key: "registered",
        label: "Fecha",
        render: value => {
          return (
            <Moment unix format="llll" locale="es">
              {value.seconds}
            </Moment>
          );
        }
      },
      {
        key: "eventName",
        label: "Evento",
        primary: true
      },
      {
        key: "username",
        label: "Usuario",
        primary: true
      },
      {
        key: "email",
        label: "Correo"
      }
    ];

    return (
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          Registros
        </Typography>
        {loading && <LinearProgress />}
        <Paper className={classes.root}>
          <ResponsiveTable
            columns={columns}
            data={registrations}
            noContentText="..."
          />
        </Paper>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withFirebase
)(RegistrationList);
