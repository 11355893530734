import React from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { SignOutIconButton } from "../SignOut";
import { AuthUserContext } from "../Session";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/Help";
import SettingsIcon from "@material-ui/icons/Settings";
import PageviewIcon from "@material-ui/icons/Pageview";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: theme.spacing.unit * -0.5, //-12
    marginRight: theme.spacing.unit * 0.5 //20
  },
  menuButtonRight: {
    marginRight: theme.spacing.unit * -0.5, //-12
    marginLeft: theme.spacing.unit * 0.5 //20
  }
});

const Navigation = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Home"
            component={Link}
            to={ROUTES.HOME}
          >
            <PageviewIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Eventos
          </Typography>
          <AuthUserContext.Consumer>
            {authUser =>
              authUser ? (
                <NavigationButtonsAuth props={props} authUser={authUser} />
              ) : (
                <NavigationButtonsNonAuth props={props} />
              )
            }
          </AuthUserContext.Consumer>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

const NavigationButtonsAuth = ({ props, authUser }) => {
  const { classes } = props;
  return (
    <React.Fragment>
      {authUser.roles.includes(ROLES.ADMIN) && (
        <IconButton
          className={classes.menuButtonRight}
          color="inherit"
          aria-label="Admin"
          component={Link}
          to={ROUTES.ADMIN}
        >
          <SettingsIcon />
        </IconButton>
      )}
      <IconButton
        className={classes.menuButtonRight}
        color="inherit"
        aria-label="Account"
        component={Link}
        to={ROUTES.ACCOUNT}
      >
        <AccountCircleIcon />
      </IconButton>
      <SignOutIconButton />
    </React.Fragment>
  );
};

const NavigationButtonsNonAuth = ({ props }) => {
  const { classes } = props;
  return (
    <React.Fragment>
      <IconButton
        className={classes.menuButtonRight}
        color="inherit"
        aria-label="Sign in"
        component={Link}
        to={ROUTES.SIGN_IN}
      >
        <LockIcon />
      </IconButton>
      <IconButton
        className={classes.menuButtonRight}
        color="inherit"
        aria-label="About"
        component={Link}
        to={ROUTES.ABOUT}
      >
        <HelpIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default withStyles(styles)(Navigation);
