import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import * as ROUTES from "../../constants/routes";

import FlamesBarcelonaBadge from "../SVG";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { withFirebase } from "../Firebase";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../Theme/withTheme";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    flex: 1,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 5,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  content: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    textAlign: "center",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,

    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  actions: {
    marginBottom: theme.spacing.unit * 3
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  googleButton: {
    //color: theme.palette.getContrastText(purple[500]),
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white[700]
    }
  }
});

const SignUpPage = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {/*<Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>*/}
          <FlamesBarcelonaBadge width={100} height={100} />
          <Typography component="h1" variant="h5">
            Crear un usuario
          </Typography>
          <SignUpForm />
        </Paper>
      </main>
    </React.Fragment>
  );
};

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  showPasswordOne: false,
  showPasswordTwo: false,
  error: null
};

const ERROR_WEAK = "auth/weak-password";
const MSG_WEAK = "La contraseña debe tener un mínimo de 6 caracteres.";
//Password should be at least 6 characters

const ERROR_RECENT = "auth/requires-recent-login";
const MSG_RECENT =
  "Esta acción es sensible y requiere una autenticación reciente. Vuelve a iniciar sesión antes de intentarlo.";
//This operation is sensitive and requires recent authentication. Log in again before retrying this request.

const ERROR_INVALID_EMAIL = "auth/invalid-email";
const MSG_INVALID_EMAIL = "La dirección de correo no tiene un formato válido.";
//The email address is badly formatted.

const ERROR_USED_EMAIL = "auth/email-already-in-use";
const MSG_USED_EMAIL = "Ya existe un usuario con esta dirección de correo.";
//The email address is already in use by another account.

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        /*this.props.firebase.user(authUser.uid).set(
          {
            username: username,
            email: authUser.email,
            photoURL:
              "https://res.cloudinary.com/flamesbarcelona/image/upload/v1548023495/Ignis/Users/user-dog.jpg"
            //roles: []
          },
          { merge: true }
        );*/
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            photoURL:
              "https://res.cloudinary.com/flamesbarcelona/image/upload/v1548023495/Ignis/Users/user-dog.jpg"
            //roles
          },
          { merge: true }
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_WEAK) {
          error.message = MSG_WEAK;
        } else if (error.code === ERROR_RECENT) {
          error.message = MSG_RECENT;
        } else if (error.code === ERROR_INVALID_EMAIL) {
          error.message = MSG_INVALID_EMAIL;
        } else if (error.code === ERROR_USED_EMAIL) {
          error.message = MSG_USED_EMAIL;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickShowPassword = prop => event => {
    this.setState({ [prop]: !this.state[prop] });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      showPasswordOne,
      showPasswordTwo,
      error
    } = this.state;
    const { classes } = this.props;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Nombre y apellidos</InputLabel>
          <Input
            id="username"
            name="username"
            autoComplete="username"
            onChange={this.handleChange}
            autoFocus
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Correo electrónico</InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="adornment-passwordOne">Contraseña</InputLabel>
          <Input
            id="adornment-passwordOne"
            type={showPasswordOne ? "text" : "password"}
            value={passwordOne}
            name="passwordOne"
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword("showPasswordOne")}
                >
                  {showPasswordOne ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="adornment-passwordTwo">
            Confirmar contraseña
          </InputLabel>
          <Input
            id="adornment-passwordTwo"
            type={showPasswordTwo ? "text" : "password"}
            value={passwordTwo}
            name="passwordTwo"
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword("showPasswordTwo")}
                >
                  {showPasswordTwo ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && (
            <FormHelperText id="component-error-text">
              {error.message} {error.code}
            </FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isInvalid}
          className={classes.submit}
        >
          Crear
        </Button>
      </form>
    );
  }
}

const SignUpLink = () => (
  <React.Fragment>
    <Typography
      align="center"
      variant="caption"
      color="textSecondary"
      component="p"
      gutterBottom
    >
      ¿No tienes una cuenta de usuario?
    </Typography>
    <Button component={Link} to={ROUTES.SIGN_UP}>
      Crear un usuario
    </Button>
  </React.Fragment>
);

const SignUpForm = compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(SignUpFormBase);

export default compose(
  withTheme,
  withStyles(styles)
)(SignUpPage);

export { SignUpForm, SignUpLink };
