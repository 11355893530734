import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import * as ROLES from "../../constants/roles";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { withStyles } from "@material-ui/core/styles";

import { DateTimePicker } from "material-ui-pickers";

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 2}px ${theme
      .spacing.unit * 6}px`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  content: {
    //backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
    maxWidth: 600,
    padding: theme.spacing.unit * 6
  },
  /* Media queries adaptations */
  heroTitle: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: theme.typography.h3.fontweight,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      letterSpacing: theme.typography.h3.letterSpacing
    }
  }
});

const AddEventPage = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      {/* Hero unit */}
      <main>
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="textPrimary"
              gutterBottom
              classes={{ h1: classes.heroTitle }}
            >
              Añadir un evento
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              paragraph
            >
              Utiliza el formulario siguiente para añadir un evento a la
              plataforma
            </Typography>
          </div>
        </div>
      </main>
      {/* End hero unit */}

      {/* Content */}
      <section className={classes.content}>
        <Typography variant="h6" align="center" gutterBottom>
          Nuevo evento
        </Typography>

        <AddEventForm />
      </section>
      {/* End Content */}
    </React.Fragment>
  );
};

const INITIAL_STATE = {
  error: null,
  title: null,
  summary: null,
  location: null,
  date: null,
  acceptsRegistration: false,
  highlight: null,
  description: [""],
  imgW: null,
  imgP: null,
  icon: null
};

class AddEventFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();

    const {
      title,
      summary,
      location,
      date,
      acceptsRegistration,
      highlight,
      description,
      imgW,
      imgP,
      icon
    } = this.state;

    this.props.firebase
      .doAddEvent(
        title,
        summary,
        location,
        date.toDate(),
        acceptsRegistration,
        highlight,
        description,
        imgW,
        imgP,
        icon
      )
      .then(doc => {
        this.setState({ ...INITIAL_STATE });
        //Clear event cache
        sessionStorage.removeItem("events");
        sessionStorage.removeItem("nextEvent");
        this.props.history.push(ROUTES.EVENT + "/" + doc.id);
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckboxChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleAddParagraph = event => {
    let description = this.state.description;
    if (description.length < 10) {
      description.push("");
      this.setState({ description });
    }
  };

  handleDeleteParagraph = event => {
    let description = this.state.description;
    if (description.length > 1) {
      description.pop();
      this.setState({ description });
    }
  };

  handleParagraphChange = id => event => {
    let description = this.state.description;
    description[id] = event.target.value;
    this.setState({ description });
  };

  handleDateChange = date => {
    this.setState({ date: date });
  };

  render() {
    const {
      /*title,
      summary,
      location,
      date,
      acceptsRegistration,
      highlight,
      description,
      imgW,
      imgP,
      icon,*/
      error
    } = this.state;
    const { classes } = this.props;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="title">Título</InputLabel>
          <Input name="title" id="title" onChange={this.onChange} />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="summary"
            name="summary"
            multiline
            label="Resumen"
            required
            placeholder="Breve descripción del evento"
            rowsMax="4"
            onChange={this.onChange}
            className={classes.textField}
            margin="normal"
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="location">Ubicación</InputLabel>
          <Input name="location" id="location" onChange={this.onChange} />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          {/*<TextField
            id="date"
            label="Fecha y hora"
            type="datetime-local"
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
          />*/}
          <DateTimePicker
            id="date"
            label="Fecha y hora"
            required
            value={this.state.date}
            onChange={this.handleDateChange}
            ampm={false}
            disablePast
            autoOk
            format="LLLL"
            emptyLabel="Fecha y hora"
          />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.acceptsRegistration}
                name="acceptsRegistration"
                id="acceptsRegistration"
                color="primary"
                onChange={this.handleCheckboxChange}
              />
            }
            label="Registro abierto"
          />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="highlight">Destacado</InputLabel>
          <Input name="highlight" id="highlight" onChange={this.onChange} />
        </FormControl>

        {this.state.description.map((paragraph, i) => (
          <FormControl margin="normal" required fullWidth key={i}>
            <TextField
              id={"paragraph-" + i}
              multiline
              type="paragraph"
              label="Párrafo"
              placeholder="Párrafo de la descripción completa"
              rowsMax="4"
              required
              onChange={this.handleParagraphChange(i)}
              className={classes.textField}
              margin="normal"
            />
          </FormControl>
        ))}
        <IconButton
          className={classes.button}
          aria-label="Add"
          onClick={this.handleAddParagraph}
          disabled={this.state.description.length === 10}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          className={classes.button}
          aria-label="Delete"
          onClick={this.handleDeleteParagraph}
          disabled={this.state.description.length === 1}
        >
          <RemoveIcon />
        </IconButton>

        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="imgW">Imagen horizontal</InputLabel>
          <Input name="imgW" id="imgW" onChange={this.onChange} />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="imgP">Imagen cuadrada</InputLabel>
          <Input name="imgP" id="imgP" onChange={this.onChange} />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="imgW">Icono</InputLabel>
          <Input name="icon" id="icon" onChange={this.onChange} />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          {/* */}
        </FormControl>

        {error && (
          <FormHelperText id="component-error-text">
            {error.message}
          </FormHelperText>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Añadir
        </Button>
      </form>
    );
  }
}

const AddEventForm = compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(AddEventFormBase);

const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);
export default compose(
  withStyles(styles),
  withAuthorization(condition)
)(AddEventPage);
