import React, { Component } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Footer from "../Footer";
import FlamesBarcelonaBadge from "../SVG";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    flex: 1,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 5,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const ForgotPasswordPage = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {/*<Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>*/}
          <FlamesBarcelonaBadge width={100} height={100} />
          <Typography component="h1" variant="h6" align="center" gutterBottom>
            Restablecer la contraseña
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Introduce el correo electrónico asociado a tu cuenta de usuario para
            recibir un enlace de restablecimiento de contraseña
          </Typography>
          <ForgotPasswordForm />
        </Paper>
      </main>
    </React.Fragment>
  );
};

const INITIAL_STATE = {
  email: "",
  error: null
};

const ERROR_INVALID_EMAIL = "auth/invalid-email";
const MSG_INVALID_EMAIL = "La dirección de correo no tiene un formato válido.";
//The email address is badly formatted.

const ERROR_USER_NOT_FOUND = "auth/user-not-found";
const MSG_USER_NOT_FOUND = "No existe un usuario para este identificador.";
//There is no user record corresponding to this identifier. The user may have been deleted.

class ForgotPasswordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        if (error.code === ERROR_INVALID_EMAIL) {
          error.message = MSG_INVALID_EMAIL;
        } else if (error.code === ERROR_USER_NOT_FOUND) {
          error.message = MSG_USER_NOT_FOUND;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Correo electrónico</InputLabel>
          <Input
            id="email"
            name="email"
            value={this.state.email}
            autoComplete="email"
            onChange={this.onChange}
            autoFocus
          />
          {error && (
            <FormHelperText id="component-error-text">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isInvalid}
          className={classes.submit}
        >
          Solicitar enlace
        </Button>
      </form>
    );
  }
}

const ForgotPasswordForm = compose(
  withStyles(styles),
  withFirebase
)(ForgotPasswordFormBase);

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const PasswordForgetLink = () => (
  <React.Fragment>
    <Typography
      align="center"
      variant="caption"
      color="textSecondary"
      component="p"
      gutterBottom
    >
      ¿Has olvidado la contraseña?
    </Typography>
    <Button component={Link} to={ROUTES.PASSWORD_FORGET}>
      Restablecer la contraseña
    </Button>
  </React.Fragment>
);

export default compose(withStyles(styles))(ForgotPasswordPage);

export { ForgotPasswordForm, PasswordForgetLink };
