import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    // Auth init
    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    // Firestore init
    this.db = app.firestore();
    //this.db.settings({ timestampsInSnapshots: true });
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      //url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  getProviderForProviderId = providerId => {
    switch (providerId) {
      case "google.com":
        return this.googleProvider;
      case "facebook.com":
        return this.facebookProvider;
    }
  };

  // *** User API ***

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  // *** Registration API ***

  registration = id => this.db.doc(`registrations/${id}`);
  registrations = () => this.db.collection("registrations");

  userRegistrations = uid =>
    this.db.collection("registrations").where("uid", "==", uid);

  userRegistrationsForEvent = (uid, eid) =>
    this.db
      .collection("registrations")
      .where("uid", "==", uid)
      .where("eid", "==", eid);

  allRegistrationsForEvent = eid =>
    this.db.collection("registrations").where("eid", "==", eid);

  doRegistration = (user, eid, event) =>
    this.db.collection("registrations").add({
      uid: user.uid,
      username: user.username,
      email: user.email,
      eid: eid,
      eventName: event.title,
      eventDate: event.date,
      eventSummary: event.summary,
      icon: event.icon,
      registered: new Date(),
      checkedIn: false
    });

  deleteRegistration = id =>
    this.db
      .doc(`registrations/${id}`)
      .delete()
      .then(() => {
        console.log("Document successfully deleted.");
      });

  doCheckIn = id =>
    this.db.doc(`registrations/${id}`).update({ checkedIn: true });

  undoCheckIn = id =>
    this.db.doc(`registrations/${id}`).update({ checkedIn: false });

  // *** Event API ***

  event = id => this.db.doc(`events/${id}`);

  events = () =>
    this.db
      .collection("events")
      .orderBy("date", "asc")
      .limit(50);

  nextEvent = () =>
    this.db
      .collection("events")
      .orderBy("date", "asc")
      .limit(1);

  openEvents = () =>
    this.db.collection("events").where("acceptsRegistration", "==", true);
  //.orderBy("date", "asc");

  closedEvents = () =>
    this.db
      .collection("events")
      .where("acceptsRegistration", "==", false)
      .orderBy("date", "asc");

  doAddEvent = (
    title,
    summary,
    location,
    date,
    acceptsRegistration,
    highlight,
    description,
    imgW,
    imgP,
    icon
  ) =>
    this.db.collection("events").add({
      title: title,
      summary: summary,
      location: location,
      date: date,
      acceptsRegistration: acceptsRegistration,
      highlight,
      description: description,
      imgW: imgW,
      imgP: imgP,
      icon: icon
    });

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });
}

export default Firebase;
