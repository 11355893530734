import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { compose } from "recompose";

import { UserList, UserItem } from "../Users";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import { withAuthorization } from "../Session";
import RegistrationList from "./RegistrationList";
import Footer from "../Footer";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  content: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6
  },
  /* Media queries adaptations */
  heroTitle: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: theme.typography.h3.fontweight,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      letterSpacing: theme.typography.h3.letterSpacing
    }
  }
});

const AdminPage = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      {/* Hero unit */}
      <main>
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="textPrimary"
              gutterBottom
              classes={{ h1: classes.heroTitle }}
            >
              Administración
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              paragraph
            >
              Esta página está restringida, sólo usuarios con rol administrador
              deberían poder acceder.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={ROUTES.ADD_EVENT}
                  >
                    Añadir un evento
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </main>
      {/* End hero unit */}

      {/* Content */}
      <section className={classes.content}>
        <Switch>
          <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
          <Route exact path={ROUTES.ADMIN} component={UserList} />
        </Switch>
      </section>
      <section className={classes.content}>
        <RegistrationList />
      </section>
      {/* End Content */}
    </React.Fragment>
  );
};

const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
  withStyles(styles)
  //withFirebase
)(AdminPage);
