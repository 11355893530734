import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

import ResponsiveTable from "material-ui-next-responsive-table";

const styles = {
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
};

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.users().onSnapshot(snapshot => {
      let users = [];

      snapshot.forEach(doc => users.push({ ...doc.data(), uid: doc.id }));

      this.setState({
        users,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { users, loading } = this.state;
    const { classes } = this.props;

    const columns = [
      {
        key: "username",
        label: "Nombre",
        primary: true
      },
      {
        key: "email",
        label: "Correo"
      },
      {
        key: "uid",
        label: "Id"
      },
      {
        key: "uid",
        label: "Acciones",
        render: value => {
          return (
            <Link
              to={{
                pathname: `${ROUTES.ADMIN}/${value}`,
                state: { value }
              }}
            >
              Details
            </Link>
          );
        }
      }
    ];

    return (
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          Usuarios
        </Typography>
        {loading && <LinearProgress />}
        <Paper className={classes.root}>
          <ResponsiveTable columns={columns} data={users} noContentText="..." />
        </Paper>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withFirebase
)(UserList);
