import React from "react";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import Footer from "../Footer";

import * as ROUTES from "../../constants/routes";

import FlamesBarcelonaBadge from "../SVG";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    textAlign: "center",
    padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 2}px ${theme
      .spacing.unit * 6}px`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  content: {
    //backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
    maxWidth: 600,
    padding: theme.spacing.unit * 6,
    flex: 1
  }
});

const LandingPage = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      {/* Hero unit */}
      <main>
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <FlamesBarcelonaBadge width={100} height={100} />
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Ignis
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              paragraph
            >
              Estás navegando por la plataforma de gestión de eventos de Flames
              Barcelona, ve con cuidado que podrías quemarte
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={ROUTES.SIGN_IN}
                  >
                    Iniciar sesión
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </main>
      {/* End hero unit */}

      {/* Content */}
      <section className={classes.content}>
        <Typography variant="h6" align="center" gutterBottom>
          ¿Problemas?
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          Contacta con nosotros en crew@flamesbarcelona.com
        </Typography>
      </section>
      {/* End Content */}
    </React.Fragment>
  );
};

export default compose(withStyles(styles))(LandingPage);
