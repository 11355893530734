import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

import {
  AuthUserContext,
  withAuthorization,
  withAuthentication
} from "../Session";
import { withFirebase } from "../Firebase";

import Moment from "react-moment";
import "moment/locale/es";

import Skeleton from "react-loading-skeleton";

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 2}px ${theme
      .spacing.unit * 6}px`,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450
    }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6
  },
  //additional for the Events listing Page
  heroMeta: {
    marginTop: theme.spacing.unit * 6
  },
  homePage: {
    flex: 1
  },
  /* Media queries adaptations */
  heroTitle: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: theme.typography.h3.fontweight,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      letterSpacing: theme.typography.h3.letterSpacing
    }
  },
  heroMetaSmall: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: theme.typography.subtitle1.fontweight,
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: theme.typography.subtitle1.lineHeight,
      letterSpacing: theme.typography.subtitle1.letterSpacing
    }
  }
});

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      events:
        sessionStorage.getItem("events") === null
          ? []
          : JSON.parse(sessionStorage.getItem("events")),
      loadingNext: false,
      nextEvent: JSON.parse(sessionStorage.getItem("nextEvent"))
    };
  }

  componentWillMount() {}

  componentDidMount() {
    /* Check cache expiration */
    let expired = false;
    let now = new Date();
    let expiration = new Date(
      JSON.parse(sessionStorage.getItem("ignisExpiresAt"))
    );
    if (!(expiration === null) && expiration.getTime() < now.getTime()) {
      expired = true;
      console.log("Cache expired");
      sessionStorage.removeItem("events");
      sessionStorage.removeItem("nextEvent");
    }

    if (
      !expired &&
      !(this.state.nextEvent === null && this.state.events.length === 0)
    ) {
      console.log("Showing events from cache");
      return;
    }

    this.setState({ loading: true, loadingNext: true });

    this.unsubscribeNext = this.props.firebase
      .nextEvent()
      .onSnapshot(snapshot => {
        console.log("Fetched next event");
        let nextEvent = null;

        snapshot.forEach(doc => (nextEvent = { ...doc.data(), id: doc.id }));

        sessionStorage.setItem("nextEvent", JSON.stringify(nextEvent));

        this.setState({
          nextEvent,
          loadingNext: false
        });
      });

    this.unsubscribe = this.props.firebase.events().onSnapshot(snapshot => {
      console.log("Fetched events");
      let events = [];

      snapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id }));
      //snapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id + 1 }));
      //snapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id + 2 }));

      /* Event cache to prevent exceeding Firebase's quota */
      let expiration = new Date();
      expiration.setMinutes(expiration.getMinutes() + 30);
      sessionStorage.setItem("ignisExpiresAt", JSON.stringify(expiration));

      sessionStorage.setItem("events", JSON.stringify(events));

      this.setState({
        events,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribeNext && this.unsubscribeNext();
  }

  render() {
    const { events, loading, nextEvent, loadingNext } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.homePage}>
        {/* 
        {loadingNext && (
          <React.Fragment>
            
            <main>
              <div className={classes.heroUnit}>
                <div className={classes.heroContent}>
                  
                  <Typography
                    component="h1"
                    variant="h1"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    classes={{ h1: classes.heroTitle }}
                  >
                    <Skeleton />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    <Skeleton count={3} />
                  </Typography>
                </div>
                <div className={classes.heroButtons}>
                  <Grid container spacing={16} justify="center">
                    <Grid item>
                      <Button>
                        <Skeleton width={120} />
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </main>
            
          </React.Fragment>
        )}
        
        {nextEvent && (
          <React.Fragment>
           
            <main>
              <div className={classes.heroUnit}>
                <div className={classes.heroContent}>
                  <Typography
                    component="h1"
                    variant="h1"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    classes={{ h1: classes.heroTitle }}
                  >
                    {nextEvent.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    {nextEvent.summary}
                  </Typography>

                  <div className={classes.heroMeta}>
                    <Grid container spacing={16} justify="center">
                      <Grid item xs>
                        <Typography
                          variant="button"
                          align="center"
                          gutterBottom
                        >
                          Ubicación
                        </Typography>
                        <Typography
                          variant="h5"
                          align="center"
                          gutterBottom
                          classes={{ h5: classes.heroMetaSmall }}
                        >
                          {nextEvent.location}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="button"
                          align="center"
                          gutterBottom
                        >
                          Fecha
                        </Typography>
                        <Typography
                          variant="h5"
                          align="center"
                          gutterBottom
                          classes={{ h5: classes.heroMetaSmall }}
                        >
                          <Moment unix format="DD/MM/YYYY">
                            {nextEvent.date.seconds}
                          </Moment>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="button"
                          align="center"
                          gutterBottom
                        >
                          Hora
                        </Typography>
                        <Typography
                          variant="h5"
                          align="center"
                          gutterBottom
                          classes={{ h5: classes.heroMetaSmall }}
                        >
                          <Moment unix format="HH:mm">
                            {nextEvent.date.seconds}
                          </Moment>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.heroButtons}>
                    <Grid container spacing={16} justify="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={ROUTES.EVENT + "/" + nextEvent.id}
                        >
                          Ver más información
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </main>
           
          </React.Fragment>
        )}

      */}

        <React.Fragment>
          <section className={classes.content}>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              <Grid container spacing={40}>
                {loading && (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <Card className={classes.card}>
                      <Skeleton height={150} />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                          <Skeleton />
                        </Typography>
                        <Typography gutterBottom variant="overline">
                          <Skeleton />
                        </Typography>
                        <Typography>
                          <Skeleton count={3} />
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="medium" color="primary">
                          <Skeleton width={70} />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )}

                {events.map(event => (
                  <Grid item key={event.id} xs={12} sm={6} md={4} lg={4} xl={3}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={event.imgW}
                        title={event.title}
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {event.title}
                        </Typography>
                        <Typography gutterBottom variant="overline">
                          <Moment unix format="llll" locale="es">
                            {event.date.seconds}
                          </Moment>
                        </Typography>
                        {event.acceptsRegistration && (
                          <Typography
                            gutterBottom
                            variant="button"
                            color="secondary"
                          >
                            Inscripción abierta
                          </Typography>
                        )}
                        <Typography>{event.summary}</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="medium"
                          color="primary"
                          component={Link}
                          to={ROUTES.EVENT + "/" + event.id}
                        >
                          Ver más
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </section>
        </React.Fragment>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

//const condition = authUser => !!authUser;

export default compose(
  //withAuthorization(condition),
  withStyles(styles),
  // withAuthentication,
  withFirebase
)(Home);

/*export default withTheme(
  withStyles(styles)(withAuthorization(condition)(Home))
);*/
